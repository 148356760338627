import {createContext, useContext, useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {getUser, resetUser, setUser} from "../utils/localStorage";

const AuthContext = createContext(undefined as any);

type ProviderAuthT = {
    logout: () => Promise<void>;
    isLoggedIn: () => boolean;
    currentUser: User | undefined;
    setCurrentUser: (user: User) => void;
    setUser: (user: User) => void;
}

export type User = any;

export const useAuth = (): ProviderAuthT => {
    return useContext(AuthContext);
}

interface IProps {
    children: JSX.Element[] | JSX.Element;
}

const AuthProvider = ({children}: IProps) => {
    const [currentUser, setCurrentUser] = useState<User>(getUser() as User);
    const navigate = useNavigate();

    useEffect(() => {
        const pathAfterLogin = window.location.pathname + window.location.search;

        if (!currentUser) {
            if (!['/login', '/register', '/reset-password', '/verify-email'].some((path) => pathAfterLogin.startsWith(path))) {
                navigate(`/login?redirect=${pathAfterLogin}`);
            }
        } else {
            if (currentUser.isSuperAdmin && pathAfterLogin.includes("/manage")) {
                navigate("/manage");
            } else if (pathAfterLogin === '/login') {
                navigate('/');
            } else {
                navigate(pathAfterLogin);
            }
        }
    }, [currentUser, navigate]);

    const logout = async () => {
        setCurrentUser(null);
        resetUser();
        navigate("/login");
    }

    const isLoggedIn = () => {
        return currentUser !== null;
    }

    const value: ProviderAuthT = {
        logout,
        isLoggedIn,
        currentUser,
        setCurrentUser,
        setUser
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;
