import React, { useState } from 'react';
import {Form, Input, Button, message, Row, Col, Select} from 'antd';
import {MailOutlined, UserOutlined, MessageOutlined, PlusOutlined} from '@ant-design/icons';
import {Flex} from "rebass";
import axios from "axios";
import {useWindowDimensions} from "../../contexts/WindowDimensionsContext";

const ContactForm = ({ backendApiUrl }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const windowDimensions = useWindowDimensions();

    const onFinish = async (values) => {
        setLoading(true);
        messageApi
            .open({
                type: 'loading',
                content: 'Sending the message...',
            })
        try {
            const response = await axios.post(`${backendApiUrl}/contact-us`, {values}, {
                headers: { "Content-Type": "application/json" },
            });
            if (response.status === 200)
                message.success("Message sent successfully!");
            else
                message.error("Failed to send message. Please try again.");
            form.resetFields();
        } catch (error) {
            message.error("Failed to send message. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Flex flexDirection={"column"} justifyContent={"center"} style={{ marginBottom: "auto" }}>
            {contextHolder}
            <h2 className="mb-15">Nous contacter</h2>

            <Row justify="center">
                <Col xs={24} sm={20} md={16} lg={12}>
                    <Form
                        form={form}
                        name="contact-form"
                        layout="vertical"
                        onFinish={onFinish}
                        style={{ maxWidth: '100%', margin: 'auto' }}
                    >
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[
                                { required: true, message: "Please enter your name" },
                                { min: 2, message: "Name must be at least 2 characters" }
                            ]}
                        >
                            <Input
                                prefix={<UserOutlined />}
                                placeholder="Your Name"
                                style={{fontSize: windowDimensions.wWCheck(500) ? "" : "16px"}}
                            />
                        </Form.Item>

                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                                { required: true, message: "Please enter your email" },
                                { type: "email", message: "Please enter a valid email address" }
                            ]}
                        >
                            <Input
                                prefix={<MailOutlined />}
                                placeholder="Your Email"
                                style={{fontSize: windowDimensions.wWCheck(500) ? "" : "16px",}}
                            />
                        </Form.Item>

                        <Form.Item
                            name="subject"
                            label="Subject"
                            rules={[{ required: true, message: "Please enter a subject" }]}
                        >
                            <Input
                                prefix={<MessageOutlined />}
                                placeholder="Subject"
                                style={{fontSize: windowDimensions.wWCheck(500) ? "" : "16px",}}
                            />
                        </Form.Item>

                        <Form.Item
                            name="message"
                            label="Message"
                            rules={[
                                { required: true, message: "Please enter your message" },
                                { min: 10, message: "Message must be at least 10 characters" }
                            ]}
                        >
                            <Input.TextArea
                                rows={8}
                                placeholder="Your Message"
                                style={{fontSize: windowDimensions.wWCheck(500) ? "" : "16px",}}
                            />
                        </Form.Item>

                        <Form.Item style={{display: "flex", justifyContent: "center", width: "100%"}}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                            >
                                Send Message
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Flex>
    );
};

export default ContactForm;
