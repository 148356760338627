import "../../css/constants.css";
import {Flex} from "rebass";
import MyText from "../../components/basics/MyText";
import React, {useEffect, useRef, useState} from "react";
import PageTitle from "../../components/PageTitle";
import {faExpand} from "@fortawesome/free-solid-svg-icons";
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {useNavigate, useSearchParams} from "react-router-dom";
import {useAuth} from "../../contexts/AuthContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Loading} from "../../components/Loading";
import {useWindowDimensions} from "../../contexts/WindowDimensionsContext";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure} from "@nextui-org/react";
import { Dropzone } from "../../components/dropzone";
import {Input, Select, Button, Switch, message, Divider, Popconfirm} from "antd";
import axios from "axios";
import ImageViewer from "react-simple-image-viewer";
import {CloseOutlined, PlusOutlined, EditOutlined, DeleteOutlined, CopyOutlined} from '@ant-design/icons';
import {scroller} from "react-scroll";
const { TextArea } = Input;

export default function News({ backendApiUrl }) {
    const navigate = useNavigate()
    const authContext = useAuth();
    const windowDimensions = useWindowDimensions();
    const [news, setNews] = useState([]);
    const [filter, setFilter] = useState("")
    const [isPictureViewerOpen, setIsPictureViewerOpen] = useState(false);
    const [pictureViewerPicture, setPictureViewerPicture] = useState("");
    const [loading, setLoading] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [file, setFile] = useState(null);
    const [fileRemoved, setFileRemoved] = useState(false);
    const [elementId, setElementId] = useState(null);
    const [newElement, setNewElement] = useState({_id: "", title: "", concern: [], content: "", url: [], picture: ""});
    const [numberOfLinks, setNumberOfLinks] = useState( 0);
    const [highlighted, setHighlighted] = useState("");
    const [sendEmail, setSendEmail] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const idToSearch = searchParams.get("id");
    const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL

    useEffect(() => {
        fetchElements()
    }, [])

    useEffect(() => {
        if (idToSearch) {
            const highlightedElement = news.find(element => element._id === idToSearch);
            if (highlightedElement) {
                setHighlighted(idToSearch);
                scroller.scrollTo(idToSearch, {
                    duration: 200,
                    smooth: true,
                    offset: -100,
                });
                setSearchParams({});
            }
        }
    }, [idToSearch, news]);

    const fetchElements = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${backendApiUrl}/news/`);
            if (response.status === 200 && response.data.response.length > 0) {
                const changed = response.data.response
                    .map((item) => {
                        const pictureUrl = item.picture
                            ? `${backendApiUrl.replace("/api", "")}/${item.picture.replace('/root/apps/kimmel-intra/dest/server/', '')}`
                            : "";
                        return { ...item, picture: pictureUrl };
                    })
                setNews(changed.sort((a, b) => {
                    const dateA = new Date(a.updatedAt || 0).getTime();
                    const dateB = new Date(b.updatedAt || 0).getTime();
                    return dateB - dateA;
                }));
            } else if (response.status === 404) {
                setNews([]);
            }
        } catch (error) {
            setNews([]);
            console.error('Error fetching news:', error);
        } finally {
            setLoading(false);
        }
    };

    const createStyledHtmlContent = (response) => {
        const newsId = elementId ? newElement._id : response.data.element._id || "noid";
        const pictureUrl = response.data.element.picture
            ? `${backendApiUrl.replace("/api", "")}/${response.data.element.picture.replace('/root/apps/kimmel-intra/dest/server/', '')}`
            : "";
        const formattedUrls = newElement.url?.map(link =>
            `<tr>
            <td style="padding: 6px 0;">
                <a href="${link.link}" style="color: #2980b9; text-decoration: underline;">${link.title}</a>
            </td>
        </tr>`
        ).join('') || "";

        return `
            <html>
            <head>
                <style>
                    body {
                        font-family: Arial, sans-serif;
                        background-color: #F5F7FA;
                        padding: 50px;
                        margin: 0;
                        color: #333;
                    }
                    h1 {
                        color: #000000;
                        font-size: 28px;
                        margin-bottom: 25px;
                        font-weight: bold;
                    }
                    .container {
                        max-width: 600px;
                        margin: 0 auto;
                        background: #FFFFFF;
                        padding: 30px;
                        border-radius: 8px;
                        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
                    }
                    .footer {
                        font-size: 12px;
                        color: #aaa;
                        text-align: center;
                        margin-top: 35px;
                    }
                    .button {
                        background-color: #1777FF;
                        color: white;
                        padding: 12px 35px;
                        border-radius: 5px;
                        text-decoration: none;
                        font-size: 16px;
                        font-weight: bold;
                        display: inline-block;
                        width: 250px;
                        margin-top: 15px;
                    }
                    .button:hover {
                        background-color: #166fed;
                    }
                    .divider {
                        border-top: 1px solid #dadada;
                        margin: 35px 0;
                    }
                    .picture-container {
                        text-align: center;
                    }
                    .content {
                         white-space: pre-wrap;
                         text-align: justify; 
                    }
                    .associate-links {
                        font-size: 18px;
                        font-weight: bold;
                    }
                </style>
            </head>
            <body>
                <div class="container">
                    <h1>${newElement.title}</h1>
                    <p class="content">${newElement.content || ''}</p>
                    
                    ${pictureUrl ?
                        `<div class="divider"></div>`
                        : ''}
            
                    ${pictureUrl ?
                        `<div class="picture-container">
                            <img src="${pictureUrl}" alt="Image" style="width: 80%; height: auto; max-height: 600px; border-radius: 5px; box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);" />
                        </div>`
                        : ''}
            
                    ${pictureUrl ?
                        `<div class="divider"></div>`
                        : ''}
            
                    ${newElement.url.length > 0 ? `<p class="associate-links">Liens associés:</p>` : ''}
            
                    ${formattedUrls ?
                        `<table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
                            ${formattedUrls}
                        </table>`
                        : ''}
            
                    <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
                        <tr>
                            <td align="center" style="padding-top: 25px;">
                                <a href="${FRONTEND_URL}/?id=${newsId}" class="button" style="color: white">
                                    Ouvrir l'actualité
                                </a>
                            </td>
                        </tr>
                    </table>
            
                    <div class="footer">
                        <p>Merci de votre attention !</p>
                        <p>La team Kimmel</p>
                    </div>
                </div>
            </body>
            </html>`;
    };

    const handleElementSubmit = async (event) => {
        event?.preventDefault();
        if (!newElement.concern || newElement.concern.length === 0) {
            message.error("Veuillez sélectionner au moins une entreprise.");
            return;
        }
        if (!newElement.title) {
            message.error("Veuillez entrer un titre pour l'actualité.");
            return;
        }

        const formData = new FormData();
        Object.keys(newElement).forEach((key) => {
            if (fileRemoved && key === "picture") {
                formData.append(key, "");
            } else if (Array.isArray(newElement[key])) {
                if (key === "url") {
                    formData.append(key, JSON.stringify(newElement[key]));
                } else {
                    newElement[key].forEach((item) => formData.append(key, item));
                }
            } else {
                formData.append(key, newElement[key]);
            }
        });

        if (file && !fileRemoved) {
            formData.append("picture", file);
        }

        setLoading(true);
        try {
            let response;

            if (elementId) {
                response = await axios.put(`${backendApiUrl}/news/id/${elementId}`, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                });
                message.success("Actualité modifiée avec succès.");
            } else {
                response = await axios.post(`${backendApiUrl}/news/`, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                });
                message.success("Nouvelle actualité créée avec succès.");
            }
            onClose();
            resetForm();
            fetchElements();
            if (sendEmail) {
                const concernedUsers = await axios.post(`${backendApiUrl}/user/concern`, {concern: newElement.concern}, {
                    headers: {"Content-Type": "application/json"},
                });
                await axios.post(`${backendApiUrl}/send-email/`, {to: concernedUsers.data, subject: elementId ? "L'actualité '" + news.find((e) => e._id === newElement._id)?.title + "' a été modifiée" : "Nouvelle actualité disponible: " + newElement.title, html: createStyledHtmlContent(response)}, {
                    headers: {"Content-Type": "application/json"},
                });
                message.success("Email envoyé aux concernés.");
            }
        } catch (error) {
            message.error("Erreur lors de la soumission de l'actualité.");
            console.error("Error submitting news:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleElementDelete = async () => {
        try {
            await axios.delete(`${backendApiUrl}/news/id/${elementId}`);
            message.success("L'actualité a été supprimée.");
            resetForm();
            fetchElements()
        } catch (error) {
            message.error("Erreur lors de la suppression de l'actualité.");
            console.error("Error deleting news:", error);
        }
    }

    const resetForm = () => {
        setElementId(null);
        setNewElement({
            _id: "",
            title: "",
            concern: [],
            content: "",
            url: [],
            picture: ""
        });
        setNumberOfLinks(0)
        setFile(null);
        setSendEmail(true)
    };

    function formatTimeDifference(timestampRaw) {
        const timestamp = new Date(timestampRaw);
        const now = new Date();
        const timeDifference = now.getTime() - timestamp.getTime();
        const minutes = Math.floor(timeDifference / (1000 * 60));
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        if (days > 0) {
            return `${days} jour${days === 1 ? "" : "s"}`;
        } else if (hours > 0) {
            return `${hours} heure${hours === 1 ? "" : "s"}`;
        } else {
            return `${minutes} minute${minutes === 1 ? "" : "s"}`;
        }
    }

    const handleTitleChange = (index, value) => {
        setNewElement(prevElement => {
            const updatedUrls = [...prevElement.url];

            if (index < updatedUrls.length) {
                updatedUrls[index].title = value;
            } else {
                updatedUrls.push({ title: value, link: "" });
            }

            return {
                ...prevElement,
                url: updatedUrls,
            };
        });
    };

    const handleLinkChange = (index, value) => {
        setNewElement(prevElement => {
            const updatedUrls = [...prevElement.url];

            if (index < updatedUrls.length) {
                updatedUrls[index].link = value;
            } else {
                updatedUrls.push({ title: "", link: value });
            }

            return {
                ...prevElement,
                url: updatedUrls,
            };
        });
    };

    const handleRemoveLink = (indexToRemove) => {
        setNumberOfLinks(prevNumberOfLinks => prevNumberOfLinks - 1);

        setNewElement(prevElement => {
            const updatedUrls = prevElement.url.filter((_, index) => index !== indexToRemove);

            return {
                ...prevElement,
                url: updatedUrls,
            };
        });
    };

    const MyCard = (props) => {
        const [isHovered, setIsHovered] = useState(false);
        const imageRef = useRef(null);
        const textRef = useRef(null);
        const [thisHighlighted, setThisHighlighted] = useState(false);

        useEffect(() => {
            if (highlighted === props.data._id) {
                setThisHighlighted(true);
                const timeoutId = setTimeout(() => {
                    setThisHighlighted(false);
                    setHighlighted("")
                }, 4750)
            }
        }, []);

        useEffect(() => {
            const setPadding = () => {
                if (imageRef.current && textRef.current) {
                    const imageWidth = imageRef.current.clientWidth;
                    if (imageWidth <= 200) {
                        textRef.current.style.paddingLeft = "25px";
                        textRef.current.style.paddingTop = "";
                    } else {
                        textRef.current.style.paddingLeft = "";
                        textRef.current.style.paddingTop = "30px";
                    }
                }
            };
            setPadding();
            window.addEventListener("resize", setPadding);
            return () => {
                window.removeEventListener("resize", setPadding);
            };
        }, []);

        return (
            <Card id={props.data._id} style={{ height: "100%" }} className={`identifiable-element ${thisHighlighted ? 'highlighted' : ''}`}>
                <Card.Header>
                    <Card.Title style={{ fontSize: "28px" }}>{props.data.title}</Card.Title>
                </Card.Header>

                <div className="p-4" style={{ height: "100%" }}>
                    <Flex className="flex-col h-100">
                        {props.data.picture && (
                            <Flex style={{ minWidth: "200px" }}>
                                <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                                    <Card.Img ref={imageRef} onClick={() => {setPictureViewerPicture(props.data.picture); setIsPictureViewerOpen(true);}} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} variant="top" src={props.data.picture} className={"border shadow-md"} style={{objectFit: "cover", borderRadius: "5px", cursor: "pointer", maxHeight: "200px", aspectRatio: "16 / 9", objectPosition: "top", filter: isHovered ? 'brightness(70%)' : 'brightness(100%)'}}/>
                                    {isHovered && (
                                        <div onClick={() => {setPictureViewerPicture(props.data.picture); setIsPictureViewerOpen(true);}} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', fontSize: '35px', cursor: 'pointer',}}>
                                            <FontAwesomeIcon icon={faExpand} />
                                        </div>
                                    )}
                                </div>
                            </Flex>
                        )}
                        <Flex ref={textRef} flexDirection="column" style={{minWidth: "300px", marginTop: props.data.picture ? "10px" : "0",}}>
                            <Card.Text style={{ textAlign: "justify", whiteSpace: "pre-wrap" }}>{props.data.content}</Card.Text>
                        </Flex>
                    </Flex>
                </div>

                {props.data.url?.length > 0 && (
                    <Card.Body className="pt-0 w-100">
                        <Flex flexDirection="column" style={{ gap: "8px", maxHeight: "150px", overflowY: "auto" }}>
                            <p style={{ marginBottom: "3px", fontSize: "14px", fontWeight: "bold"}} className={"text-lg"}>Liens associés:</p>
                            {props.data.url.map((link, index) => (
                                <Flex
                                    key={index}
                                    alignItems="center"
                                    style={{
                                        flexWrap: "wrap",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Card.Link
                                        onClick={() =>
                                            link.link.startsWith("https://") ? window.open(link.link, "_blank", "noopener,noreferrer") : navigate(link.link)
                                        }
                                        style={{
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                            textUnderlineOffset: "3px",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            maxWidth: "95%",
                                        }}
                                        title={link.link}
                                    >
                                        {link.title}
                                    </Card.Link>
                                </Flex>
                            ))}
                        </Flex>
                    </Card.Body>
                )}

                <Card.Footer className="mt-auto py-3">
                    <Flex flexDirection="column" justifyContent="space-between" flexWrap="wrap">
                        <Flex className={"px-4 mb-2"} width={"100%"} style={{gap: "10px"}}>
                            {authContext.currentUser?.isAdmin &&
                                <Button icon={<CopyOutlined/>} style={{width: "100%"}} className={"mb-2"} onMouseDown={(e) => {e.preventDefault();}} onBlur={(e) => e.preventDefault()} onClick={() => {navigator.clipboard.writeText("/?id="+props.data._id).then(() => {message.success("Le lien à été copié" + " ("+props.data.title+")")}).catch((err) => {console.error(err)})}}>
                                    Copier le lien
                                </Button>
                            }
                            {authContext.currentUser?.isAdmin && (
                                <Button icon={<EditOutlined />} style={{ width: "max-content" }} className="mb-2 mx-auto w-100" onClick={() => {setNewElement({ ...props.data, url: props.data.url || [] });setNumberOfLinks(props.data.url ? props.data.url.length : 0);setElementId(props.data._id);setFile(props.data.picture);onOpen();}}>
                                    Modifier
                                </Button>
                            )}
                        </Flex>

                        <div style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "5px" }}>
                            <MyText className="text-muted mx-auto" style={{ fontSize: "var(--MiniFontSize)" }} value={"Entreprises concernées: "}/>
                            {props.data.concern.map((item, idx) => (
                                <MyText key={item} className="text-muted mx-auto lowercase" style={{ fontSize: "var(--MiniFontSize)" }} value={item === "All" ? "" : item + (idx !== props.data.concern.length - 1 ? ", " : "")}/>
                            ))}
                        </div>

                        {props.data.updatedAt && (
                            <small className="text-muted mt-auto mx-auto pe-3">
                                {"Dernière mise à jour il y a " + formatTimeDifference(props.data.updatedAt)}
                            </small>
                        )}
                    </Flex>
                </Card.Footer>
            </Card>

        )
    }

    const renderFilteredNews = () => {
        const userConcernSet = new Set();
        let numberElementsDisplayed = 0;

        authContext.currentUser?.concern.forEach((element) => {
            userConcernSet.add(element);
        });

        const userConcerns = Array.from(userConcernSet);

        const elementFilter = (element, userConcern, filter) => {
            const matchesUserConcern = authContext.currentUser?.isAdmin ||
                element.concern.some(r => userConcern.includes(r));
            const matchesFilter = filter === "" || element.concern.includes(filter);

            return matchesUserConcern && matchesFilter;
        };

        const renderDividerSection = (filter) => {
            const filtered = news.filter(thisElement => elementFilter(thisElement, userConcerns, filter));

            if (filtered.length > 0) {
                numberElementsDisplayed += filtered.length;

                return (
                    <div style={{ width: "100%" }} key={filter}>
                        <Divider className={"my-6"} style={{borderColor: "var(--Blue", color: "var(--Blue", fontWeight: "bold" }}>{filter + " (" + filtered.length + ")"}</Divider>
                        <Row xxl={3} style={{ width: "100%" }} className="g-4 mt-0 mb-1">
                            {filtered.map((thisElement, elementIndex) => (
                                <Col style={{ maxWidth: windowDimensions.wWCheck(900) ? "50%" : "" }} key={elementIndex}>
                                    <MyCard data={thisElement} key={elementIndex} />
                                </Col>
                            ))}
                        </Row>
                    </div>
                );
            }
            return null;
        };

        if (filter === "") {
            return userConcerns.map((concern) => renderDividerSection(concern));
        } else {
            return renderDividerSection(filter) || (
                <div style={{ width: "100%" }}>
                    <Flex justifyContent={"center"} className={"text-muted"} style={{ width: "100%", marginTop: "100px" }} key="no-news">
                        <MyText value={"Rien à afficher pour le moment"} style={{ fontSize: "var(--MediumFontSize)" }} />
                    </Flex>
                </div>
            );
        }
    };

    return (
        <div style={{ marginBottom: "auto" }}>
            <PageTitle title={"Actualités"}>
                {authContext.currentUser?.isAdmin &&
                    <Button onClick={onOpen} type={"primary"} icon={<PlusOutlined/>} style={{ height: "min-content", marginTop: "auto", marginBottom: "auto" }} className="me-2">
                        Ajouter une actualité
                    </Button>
                }
                {authContext.currentUser?.concern && authContext.currentUser?.concern.length > 1 &&
                    <Select onChange={(values) => values === undefined ? setFilter("") : setFilter(values)} className={windowDimensions.wWCheck(500) ?  "w-56 mr-5 ml-auto" : "w-100"} placeholder="Filtrer l'entreprise" allowClear={true}>
                        {authContext.currentUser?.concern.sort().map((concern) => (
                            <Select.Option key={concern} value={concern}>{concern}</Select.Option>
                        ))}
                    </Select>
                }
            </PageTitle>

            <Flex justifyContent={"center"} alignItems={"center"} flexWrap={"wrap"}>
                {loading && <Loading />}
                <Row xxl={3} style={{ width: "100%" }} className="g-4 mb-5">
                    {!loading && (news.length === 0 ?
                        <Flex justifyContent={"center"} className={"text-muted"} style={{ width: "100%", marginTop: "150px" }}>
                            <MyText value={"Rien à afficher pour le moment"} style={{ fontSize: "var(--MediumFontSize)" }} />
                        </Flex> :
                        renderFilteredNews()
                    )}
                </Row>
            </Flex>

            {isOpen && (
                <div className="modal-backdrop" onClick={() => {onClose();resetForm();}}>
                    <Modal style={{ padding: "0px", width: "100%" }} hideCloseButton={true} isOpen={isOpen} className={"modal flex flex-col h-max overflow-y-auto"} onClick={(e) => e.stopPropagation()}>
                        <ModalContent className={"rounded-lg border shadow-sm bg-white max-w-96 overflow-y-auto"}>
                            <ModalHeader className={"font-bold text-2xl mt-3 mb-2 mx-auto"}>
                                {elementId ? "MODIFIER UNE ACTUALITE" : "CRÉER UNE ACTUALITÉ"}
                            </ModalHeader>
                            <form onSubmit={handleElementSubmit}>
                                <ModalBody>
                                    <div className="w-full">
                                        <span className="block text-gray-500 font-medium mb-1">Entreprises concernée(s) <span className={"text-custom-blue"}>*</span></span>
                                        <Select value={newElement.concern} onChange={(values) => setNewElement({ ...newElement, concern: values })} mode="multiple" placeholder="Sélectionnez les entreprises concernées" className="w-full rounded-lg">
                                            {authContext.currentUser?.concern.map((concern) => (
                                                <Select.Option key={concern} value={concern}>
                                                    {concern}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                    <div className="w-full">
                                        <span className="block text-gray-500 font-medium mb-1">Titre de l'actualité <span className={"text-custom-blue"}>*</span></span>
                                        <Input type="text" placeholder="Titre de l'actualité" value={newElement.title} onChange={(e) => setNewElement({ ...newElement, title: e.target.value })} className="w-full rounded-lg" style={{fontSize: windowDimensions.wWCheck(500) ? "" : "16px"}}/>
                                    </div>
                                    <div className="w-full">
                                        <span className="block text-gray-500 font-medium mb-1">Contenu de l'actualité</span>
                                        <TextArea autoSize type="text" placeholder="Contenu de l'actualité" value={newElement.content} onChange={(e) => setNewElement({ ...newElement, content: e.target.value })} className="w-full rounded-lg"/>
                                    </div>
                                    <div className="w-full">
                                        <span className="block text-gray-500 font-medium mb-1">Image</span>
                                        <Dropzone onChange={(file) => setFile(file)} initialFile={file} setFileRemoved={setFileRemoved} fileType={"image"}/>
                                    </div>
                                    {Array.from(Array(numberOfLinks).keys()).map((link, index) => (
                                        <div className="w-full">
                                            <span className="block text-gray-500 font-medium mb-1">Lien {index+1}</span>
                                            <Flex justifyContent={"space-between"} key={index}>
                                                <Input value={newElement.url[index]?.title || ""} onChange={(e) => handleTitleChange(index, e.target.value)} placeholder={`Titre ${index + 1}`} style={{fontSize: windowDimensions.wWCheck(500) ? "" : "16px", width: "100%", marginRight: "3px"}}/>
                                                <Input value={newElement.url[index]?.link || ""} onChange={(e) => handleLinkChange(index, e.target.value)} placeholder={`$Url ${index + 1}`} style={{fontSize: windowDimensions.wWCheck(500) ? "" : "16px", width: "100%", marginLeft: "3px"}}/>
                                                <Button icon={<CloseOutlined />} style={{height: "min-content", marginTop: "auto", marginBottom: "auto", marginLeft: "6px", paddingLeft: "4px", paddingRight: "4px", color: "red"}} onClick={() => handleRemoveLink(index)}/>
                                            </Flex>
                                        </div>
                                    ))}
                                    <Button icon={<PlusOutlined />} type={"text"} disabled={numberOfLinks >= 5} style={{ width: "100%" }} className="me-2" onClick={() => {if (numberOfLinks < 5) setNumberOfLinks(numberOfLinks + 1);}}>
                                        Ajouter un lien
                                    </Button>
                                    <div className="w-full">
                                        <span className="block text-gray-500 font-medium mb-1">Envoyer un email aux concernés</span>
                                        <Switch onClick={() => setSendEmail(!sendEmail)} checked={sendEmail} checkedChildren="Oui" unCheckedChildren="Non" className={"min-w-14 ml-auto"}/>
                                    </div>
                                </ModalBody>
                                <ModalFooter className={"flex flex-col justify-between mt-3"}>
                                    <div className={"flex justify-between gap-2"}>
                                        <Button icon={<CloseOutlined/>} className={"rounded-lg w-44"} onClick={() => {onClose();resetForm();}}>
                                            Annuler
                                        </Button>
                                        {elementId && (
                                            <Popconfirm
                                                title="Supprimer l'actualité"
                                                description="Êtes vous sur de vouloir la supprimer ?"
                                                okText="Oui"
                                                cancelText="Non"
                                                onConfirm={() => {onClose(); resetForm();handleElementDelete()}}
                                            >
                                                <Button icon={<DeleteOutlined/>} type="primary" danger={true} className={"rounded-lg w-44 mx-auto"}>
                                                    Supprimer
                                                </Button>
                                            </Popconfirm>
                                        )}
                                        {elementId ? (
                                            <Popconfirm
                                                title="Modifier l'actualité"
                                                description="Êtes-vous sûr de vouloir la modifier ?"
                                                okText="Oui"
                                                cancelText="Non"
                                                onConfirm={handleElementSubmit}
                                            >
                                                <Button
                                                    icon={<EditOutlined />}
                                                    type="primary"
                                                    className="rounded-lg w-44"
                                                >
                                                    Modifier
                                                </Button>
                                            </Popconfirm>
                                        ) :
                                            <Popconfirm
                                                title="Créer l'actualité"
                                                description="Êtes-vous sûr de vouloir la créer ?"
                                                okText="Oui"
                                                cancelText="Non"
                                                onConfirm={handleElementSubmit}
                                            >
                                                <Button
                                                    icon={<PlusOutlined />}
                                                    type="primary"
                                                    className="rounded-lg w-44"
                                                >
                                                    Créer
                                                </Button>
                                            </Popconfirm>
                                        }
                                    </div>
                                </ModalFooter>
                            </form>
                        </ModalContent>
                    </Modal>
                </div>
            )}

            {loading && <p className="mt-6 text-center">Chargement...</p>}

            {isPictureViewerOpen && (
                <div style={{ position: "relative", zIndex: "9999" }}>
                    <ImageViewer src={[pictureViewerPicture]} onClose={() => setIsPictureViewerOpen(false)} disableScroll={false} backgroundStyle={{backgroundColor: "rgba(0,0,0,0.9)"}} closeOnClickOutside={true}/>
                </div>
            )}
        </div>
    );
};