import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { Button, Input, message, Watermark, Popconfirm } from "antd";
import { faEnvelope, faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Logo from "../../assets/pictures/truck.png";
import {useWindowDimensions} from "../../contexts/WindowDimensionsContext";

const Register = ({ backendApiUrl }) => {
    const windowDimensions = useWindowDimensions();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        concern: [],
        userValidated: false,
        isAdmin: false,
        isSuperAdmin: false,
        isEmailValid: false,
    });
    const [status, setStatus] = useState({
        emailStatus: 0,
        passwordStatus: 0,
    });
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false)

    const handleInputChange = (field, value) => {
        setFormData(prev => ({ ...prev, [field]: value }));

        if (field === "email") {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            setStatus(prev => ({ ...prev, emailStatus: emailPattern.test(value) ? 'success' : 'error' }));
        }
        if (field === "password") {
            setStatus(prev => ({ ...prev, passwordStatus: value.length > 5 ? 'success' : 'error' }));
        }
    };

    const handleRegister = async () => {
        const { firstName, lastName, email, password } = formData;

        if (!firstName || !lastName || !email || !password) {
            message.error("Veuillez remplir tous les champs.");
            return;
        }

        setLoading(true)
        messageApi
            .open({
                type: 'loading',
                content: "Votre compte est en cours de création...",
            })

        try {
            const response = await axios.post(`${backendApiUrl}/user`, formData, {
                headers: { "Content-Type": "application/json" },
            });

            if (response.data.success) {
                message.success("Compte créé avec succès.");
                navigate('/login');
            } else {
                message.error("Erreur lors de la création du compte.");
            }
        } catch (error) {
            console.error(error);
            message.error("Une erreur est survenue.");
        } finally {
            setLoading(false)
        }
    };

    return (
        <Watermark zIndex={-1} content="Team Kimmel">
            <div style={{ height: windowDimensions.windowHeight }} className={"overflow-auto"}>
                {contextHolder}
                <div className={"overflow-auto"}>
                    <Row className={`justify-content-center align-items-center mx-2 overflow-auto`} style={{
                        minHeight: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <Col
                            className={windowDimensions.wWCheck(700) ? "mb-32" : ""}
                            style={{
                                background: "var(--White)",
                                padding: windowDimensions.wWCheck(700) ? "35px" : "20px",
                                borderRadius: "15px",
                                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                                maxWidth: windowDimensions.wWCheck(700) ? "500px" : "400px",
                                width: "100%",
                                overflowY: "auto"
                            }}
                            xs={12} sm={7} md={6} lg={4} xl={3}
                        >
                        <div className="text-center mb-6">
                            <h1 className="text-1500 text-3xl">Intranet Team Kimmel</h1>
                        </div>
                        <div className="d-flex align-items-center justify-content-center mb-6">
                            <img src={Logo} alt="ReduCO2" style={{ filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3))", borderRadius: "10px", maxWidth: windowDimensions.wWCheck(700) ? '350px' : "250px" }} />
                        </div>
                        <div className="text-center mb-6">
                            <h3 className="text-1500 text-2xl text-custom-blue">Inscription</h3>
                            <p className="text-700 mt-0.5">Veuillez remplir les informations pour créer un compte</p>
                        </div>
                        <form>
                            <div style={{ marginBottom: '10px' }}>
                                <label htmlFor="firstName" style={{ fontSize: '14px', fontWeight: '500' }}>Prénom</label>
                                <Input
                                    id="firstName"
                                    placeholder="Prénom"
                                    value={formData.firstName}
                                    onChange={(e) => handleInputChange('firstName', e.target.value)}
                                    prefix={<FontAwesomeIcon icon={faUser} style={{ fontSize: windowDimensions.wWCheck(500) ? "" : "16px", color: 'gray', paddingRight: "10px" }} />}
                                />
                            </div>

                            <div style={{ marginBottom: '10px' }}>
                                <label htmlFor="lastName" style={{ fontSize: '14px', fontWeight: '500' }}>Nom</label>
                                <Input
                                    id="lastName"
                                    placeholder="Nom"
                                    value={formData.lastName}
                                    onChange={(e) => handleInputChange('lastName', e.target.value)}
                                    prefix={<FontAwesomeIcon icon={faUser} style={{ fontSize: windowDimensions.wWCheck(500) ? "" : "16px", color: 'gray', paddingRight: "10px" }} />}
                                />
                            </div>

                            <div style={{ marginBottom: '10px' }}>
                                <label htmlFor="email" style={{ fontSize: '14px', fontWeight: '500' }}>Adresse e-mail</label>
                                <Input
                                    type="email"
                                    placeholder="Adresse e-mail"
                                    value={formData.email}
                                    onChange={(e) => handleInputChange('email', e.target.value)}
                                    status={status.emailStatus}
                                    prefix={<FontAwesomeIcon icon={faEnvelope} style={{ fontSize: windowDimensions.wWCheck(500) ? "" : "16px", color: status.emailStatus === 'error' ? 'red' : 'gray', paddingRight: "10px" }} />}
                                />
                            </div>

                            <div style={{ marginBottom: '10px' }}>
                                <label htmlFor="password" style={{ fontSize: '14px', fontWeight: '500' }}>Mot de passe</label>
                                <Input.Password
                                    id="password"
                                    placeholder="Mot de passe"
                                    value={formData.password}
                                    onChange={(e) => handleInputChange('password', e.target.value)}
                                    status={status.passwordStatus}
                                    prefix={<FontAwesomeIcon icon={faKey} style={{ fontSize: windowDimensions.wWCheck(500) ? "" : "16px", color: status.passwordStatus === 'error' ? 'red' : 'gray', paddingRight: "10px" }} />}
                                />
                            </div>

                            <Button
                                type="primary"
                                className="w-100 mt-3"
                                onClick={handleRegister}
                                loading={loading}
                                disabled={!formData.firstName || !formData.lastName || !formData.password || !formData.email || status.emailStatus === 'error' || status.passwordStatus === 'error'}
                            >
                                S'inscrire
                            </Button>
                        </form>
                        <Button
                            className="w-100 mt-2 py-3"
                            onClick={() => navigate('/login')}
                        >Se connecter</Button>
                    </Col>
                </Row>
            </div>
            </div>
        </Watermark>
    );
};

export default Register;
