import {Route, Routes} from "react-router-dom";
import {useEffect} from "react";
import {useAuth} from "./contexts/AuthContext";
import {useCounter} from "./contexts/CounterContext";
import InDevelopment from "./pages/General/InDeveloppement";
import NotFound from "./pages/General/NotFound";
import Counter from "./pages/General/Counter";
import Login from "./pages/Auth/Login";
import Calendar from "./pages/Calendar/Calendar";
import 'react-toastify/dist/ReactToastify.css';
import News from "./pages/News/News";
import Documents from "./pages/Documents/Documents";
import Main from "./pages/General/Main";
import Manage from "./pages/Manage/Manage";
import Profile from "./pages/Auth/Profile";
import Media from "./pages/Media/Media";
import Contacts from "./pages/Contacts/Contacts"
import ContactForm from "./pages/Auth/ContactForm";
import Register from "./pages/Auth/Register";
import ResetPassword from "./pages/Auth/ResetPassword";
import VerifyEmail from "./pages/Auth/VerifyEmail";
import axios from "axios";

export function MyRoutes() {
    const counterContext = useCounter()
    const authContext = useAuth()
    const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || "https://test-frederic.cloud/api";

    const getCounter = (name: string) => {
        axios.get(`${BACKEND_API_URL}/counter/name/${name}`, {
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            if (name === "totalnumber")
                counterContext.setTotalCounter((window.location.pathname.toLocaleLowerCase() !== "/counter") ? res.data.response[0].number+1 : res.data.response[0].number)
            if (name === "daynumber")
                counterContext.setDayCounter((window.location.pathname.toLocaleLowerCase() !== "/counter") ? res.data.response[0].number+1 : res.data.response[0].number)
            if (name === "resetnumber")
                counterContext.setResetCounter((window.location.pathname.toLocaleLowerCase() !== "/counter") ? res.data.response[0].number+1 : res.data.response[0].number)
        }).catch((err) => {
            if (err.response.status === 404) {
                axios.post(`${BACKEND_API_URL}/counter`, {name: name, number: 0}, {
                    headers: { "Content-Type": "application/json" },
                }).then(() => {
                    getCounter(name)
                })
            } else
                console.error(err)
        })
    }

    const increaseCounter = (name: string) => {
        axios.get(`${BACKEND_API_URL}/counter/name/${name}`, {
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            axios.put(`${BACKEND_API_URL}/counter/name/${name}`, {name: name, number: (res.data.response[0].number+1)}, {
                headers: { "Content-Type": "application/json" },
            }).then((res2) => {

            })
        }).catch((err) => {console.error(err)})
    }

    const manageCounters = async () => {
        await getCounter("totalnumber")
        await getCounter("daynumber")
        await getCounter("resetnumber")
        if (window.location.pathname.toLocaleLowerCase() !== "/counter" && !authContext.isLoggedIn()) {
            increaseCounter("totalnumber")
            increaseCounter("daynumber")
            increaseCounter("resetnumber")
        }
    }

    const checkAdminUsersExists = () => {
        axios.get(`${BACKEND_API_URL}/user/email/forster.frederic@gmail.com`, {
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            if (res.status === 200 && res.data.success === false)
                axios.post(`${BACKEND_API_URL}/user/`, {firstName: "Frédéric", lastName: "Forster", email: "forster.frederic@gmail.com", password: "Frederic137*", concern: ["KIMMEL FINANCES", "KIMMEL SERVICES", "KIMMEL LAVAGE", "KIMMEL BETON", "KIMMEL TRANSPORTS", "KIMMEL LOGISTIK", "CTVL DE THAL", "SUPL TACHY", "LAVILLE TRANSPORTS", "SUPL SERVICES", "SUPL PNEUMATIK", "SLK LOGISTIC", "STOCKEDIS PLUS", "WEBER TRANSPORTS"], userValidated: true, isAdmin: true, isSuperAdmin: true, isEmailValid: true},{
                    headers: { "Content-Type": "application/json" },
                }).then(() => {
                    console.log("Mandatory admin user \"forster.frederic@gmail.com\" created")
                })
        }).catch((err) => {console.error(err)})
    }

    const checkConcernsExists = () => {
        const elements = [
            "CTVL DE THAL",
            "SUPL TACHY",
            "KIMMEL FINANCES",
            "KIMMEL LOGISTIK",
            "KIMMEL SERVICES",
            "KIMMEL LAVAGE",
            "KIMMEL TRANSPORTS",
            "LAVILLE TRANSPORTS",
            "SUPL SERVICES",
            "SUPL PNEUMATIK",
            "SLK LOGISTIC",
            "STOCKEDIS PLUS",
            "WEBER TRANSPORTS",
            "KIMMEL BETON"
        ]

        elements.map((thisElement) => {
            axios.get(`${BACKEND_API_URL}/concern/name/${thisElement}`, {
                headers: { "Content-Type": "application/json" },
            }).then((res) => {
                if (res.status === 200 && res.data.success === false)
                    axios.post(`${BACKEND_API_URL}/concern`, {name: thisElement} ,{
                        headers: { "Content-Type": "application/json" },
                    }).then(() => console.log("Concern '"+thisElement+"' created")).catch((err) => console.error(err))
            }).catch((err) => {console.error(err)})
        })
    }

    const checkDocumentsFoldersExists = () => {
        const elements = [
            "DOSSIER 1",
            "DOSSIER 2",
            "DOSSIER 3",
            "DOSSIER 4",
            "DOSSIER 5",
            "DOSSIER 6",
            "DOSSIER 7",
            "DOSSIER 8",
            "DOSSIER 9",
            "DOSSIER 10",
            "DOSSIER 11",
            "DOSSIER 12",
            "DOSSIER 13",
            "DOSSIER 14",
            "DOSSIER 15",
            "DOSSIER 16",
            "DOSSIER 17",
            "DOSSIER 18",
        ]

        elements.map((thisElement) => {
            axios.get(`${BACKEND_API_URL}/documents-folders/name/${thisElement}`, {
                headers: { "Content-Type": "application/json" },
            }).then((res) => {
                if (res.status === 200 && res.data.success === false)
                    axios.post(`${BACKEND_API_URL}/documents-folders`, {name: thisElement} ,{
                        headers: { "Content-Type": "application/json" },
                    }).then(() => console.log("Document Folder '"+thisElement+"' created")).catch((err) => console.error(err))
            }).catch((err) => {console.error(err)})
        })
    }

    useEffect(() => {
        manageCounters()
        checkAdminUsersExists()
        process.env.REACT_APP_IS_PROD === "FALSE" && checkDocumentsFoldersExists()
        checkConcernsExists()
    }, [])

    return (
        <Routes>
            <Route path="/login" element={<Login backendApiUrl={BACKEND_API_URL}/>}/>
            <Route path="/register" element={<Register backendApiUrl={BACKEND_API_URL}/>}/>
            <Route path="/reset-password" element={<ResetPassword backendApiUrl={BACKEND_API_URL}/>}/>
            <Route path="/verify-email" element={<VerifyEmail backendApiUrl={BACKEND_API_URL}/>}/>
            <Route path="/" element={<Main/>}>
                <Route path="/" element={<News backendApiUrl={BACKEND_API_URL}/>}/>
                <Route path="/calendar" element={<Calendar backendApiUrl={BACKEND_API_URL}/>}/>
                <Route path="/documents" element={<Documents backendApiUrl={BACKEND_API_URL}/>}/>
                <Route path="/media" element={<Media backendApiUrl={BACKEND_API_URL}/>}/>
                <Route path="/contact" element={<Contacts backendApiUrl={BACKEND_API_URL}/>}/>
                <Route path="/manage" element={<Manage backendApiUrl={BACKEND_API_URL}/>}/>
                <Route path="/counter" element={<Counter backendApiUrl={BACKEND_API_URL}/>}/>
                <Route path="/profile" element={<Profile backendApiUrl={BACKEND_API_URL}/>}/>
                <Route path="/contact-us" element={<ContactForm backendApiUrl={BACKEND_API_URL}/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Route>
            <Route path="/in-development" element={<InDevelopment/>}/>
            <Route path="/counter" element={<Counter/>}/>
        </Routes>
    );
}

export default MyRoutes;
