import { useAuth } from '../../contexts/AuthContext';
import {useNavigate, useSearchParams} from 'react-router-dom';
import React, {useEffect, useRef, useState} from 'react';
import { Col, Row } from 'react-bootstrap';
import { faKey, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import '../../css/constants.css';
import Logo from "../../assets/pictures/truck.png";
import {Button, message, Watermark, Input, Popconfirm} from "antd";
import { useWindowDimensions } from "../../contexts/WindowDimensionsContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from "axios";
import {CloseOutlined, EditOutlined} from "@ant-design/icons";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure} from "@nextui-org/react";

const Login = ({ backendApiUrl }) => {
    const [email, setEmail] = useState('');
    const [resetPasswordEmail, setResetPasswordEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailStatus, setEmailStatus] = useState(0);
    const [resetPasswordEmailStatus, setResetPasswordEmailStatus] = useState(0);
    const [passwordStatus, setPasswordStatus] = useState(0);
    const [error, setError] = useState(0);
    const windowDimensions = useWindowDimensions();
    const auth = useAuth();
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const formRef = useRef(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const redirectPath = searchParams.get("redirect");
    const [redirectTo, setRedirectTo] = useState("")
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        if (redirectPath) {
            setRedirectTo(redirectPath)
            setSearchParams({});
            window.history.replaceState({}, document.title, '/login');
        }
    }, [redirectPath, navigate]);

    const validateInputs = () => {
        if (!email || !password) {
            message.error("Veuillez remplir tous les champs.");
            return false;
        }

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            message.error("Veuillez entrer une adresse e-mail valide.");
            return false;
        }

        return true;
    };

    const handleLogin = async () => {
        if (!validateInputs())
            return;

        try {
            const response = await axios.post(
                `${backendApiUrl}/user/login`,
                { email, password },
                { headers: { "Content-Type": "application/json" } }
            );

            const resData = response.data;

            if (!resData.success) {
                if (resData.email) {
                    setError(1);
                    message.error(`Veuillez valider votre compte via le mail envoyé à '${resData.email}'.`);
                } else if (resData.error === "User not validated by a super admin.") {
                    setError(1);
                    message.error(`Votre compte n'a pas encore été validé par un super administrateur.`);
                } else {
                    setError(1);
                    message.error("Identifiants incorrects.");
                }
            } else {
                setError(0);
                auth.setCurrentUser(resData.response);
                auth.setUser(resData.response);
                if (redirectTo) {
                    navigate(redirectTo, { replace: true });
                } else {
                    navigate("/", { replace: true });
                }
            }
        } catch (err) {
            console.error(err);
            if (error !== 2) {
                setError(2);
                message.error("Une erreur est survenue.");
            }
        }
    };

    const handleResetPasswordEmailChange = (e) => {
        setResetPasswordEmail(e.target.value);
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setResetPasswordEmailStatus(emailPattern.test(e.target.value) ? 'success' : 'error');
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setEmailStatus(emailPattern.test(e.target.value) ? 'success' : 'error');
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordStatus(e.target.value.length > 5 ? 'success' : 'error');
    };

    const handlePasswordReset = async () => {
        setResetPasswordEmailStatus(0);
        setResetPasswordEmail("");
        onClose();

        messageApi
            .open({
                type: 'loading',
                content: "Envoi du mail de réinitialisation de mot de passe...",
            })
        try {
            const response = await axios.post(
                `${backendApiUrl}/user/reset-password-email`,
                { resetPasswordEmail },
                { headers: { "Content-Type": "application/json" } }
            );

            if (response.data.success) {
                message.success("Un e-mail de réinitialisation a été envoyé à '" + resetPasswordEmail + "'.");
            } else {
                message.error(response.data.error || "Échec de l'envoi de l'e-mail de réinitialisation du mot de passe.");
            }
        } catch (error) {
            message.error("Erreur lors de la tentative de réinitialisation du mot de passe.");
        }
    };

    return (
        <Watermark zIndex={-1} content="Team Kimmel">
            <div style={{ height: windowDimensions.windowHeight }} className={"overflow-auto"}>
                {contextHolder}
                <div className={"overflow-auto"}>
                    <Row className={`justify-content-center align-items-center mx-2 overflow-auto`} style={{
                        minHeight: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <Col
                            className={windowDimensions.wWCheck(700) ? "mb-32" : ""}
                            style={{
                                background: "var(--White)",
                                padding: windowDimensions.wWCheck(700) ? "35px" : "20px",
                                borderRadius: "15px",
                                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                                maxWidth: windowDimensions.wWCheck(700) ? "500px" : "400px",
                                width: "100%",
                                overflowY: "auto"
                            }}
                            xs={12} sm={7} md={6} lg={4} xl={3}
                        >
                            <div className="text-center mb-6">
                                <h1 className="text-1500 text-3xl">Intranet Team Kimmel</h1>
                            </div>
                            <div className="d-flex align-items-center justify-content-center mb-6">
                                <img src={Logo} alt="Team Kimmel" style={{filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3))", borderRadius: "10px", maxWidth: windowDimensions.wWCheck(700) ? '350px' : "250px"}}/>
                            </div>
                            <div className="text-center mb-6">
                                <h3 className="text-1500 text-2xl text-custom-blue">Connexion</h3>
                                <p className="text-700 mt-0.5">Veuillez entrer vos informations pour vous connecter</p>
                            </div>
                            <form ref={formRef}>
                                <div style={{ marginBottom: '10px' }}>
                                    <label htmlFor="email" style={{ fontSize: '14px', fontWeight: '500' }}>Adresse e-mail</label>
                                    <div style={{ position: 'relative', marginTop: '5px' }}>
                                        <Input
                                            type="email"
                                            placeholder="Adresse e-mail"
                                            value={email}
                                            onChange={handleEmailChange}
                                            status={emailStatus}
                                            prefix={<FontAwesomeIcon icon={faEnvelope} style={{ color: emailStatus === 'error' ? 'red' : 'gray', paddingRight: "10px" }} />}
                                            style={{
                                                fontSize: windowDimensions.wWCheck(500) ? "" : "16px",
                                                paddingLeft: '15px',
                                                borderColor: emailStatus === 'error' ? 'red' : emailStatus === 'success' ? 'green' : undefined,
                                            }}
                                        />
                                    </div>
                                </div>

                                <div style={{ marginBottom: '10px' }}>
                                    <label htmlFor="password" style={{ fontSize: '14px', fontWeight: '500' }}>Mot de passe</label>
                                    <div style={{ position: 'relative', marginTop: '5px' }}>
                                        <Input.Password
                                            id="password"
                                            placeholder="Mot de passe"
                                            value={password}
                                            onChange={handlePasswordChange}
                                            status={passwordStatus}
                                            prefix={<FontAwesomeIcon icon={faKey} style={{ color: passwordStatus === 'error' ? 'red' : 'gray', paddingRight: "10px" }} />}
                                            style={{
                                                fontSize: windowDimensions.wWCheck(500) ? "" : "16px",
                                                paddingLeft: '15px',
                                                borderColor: passwordStatus === 'error' ? 'red' : passwordStatus === 'success' ? 'green' : undefined,
                                            }}
                                        />
                                    </div>
                                    <div className="position-relative">
                                        <span
                                            className={"text-custom-blue"}
                                            style={{
                                                fontSize: '12px',
                                                marginTop: '5px',
                                                cursor: 'pointer',
                                                display: 'inline-block',
                                                textAlign: 'right',
                                                width: '100%',
                                            }}
                                            onClick={() => onOpen()}
                                        >
                                            Mot de passe oublié ?
                                        </span>
                                    </div>
                                </div>

                                <Button
                                    type="primary"
                                    className="w-100 mt-3"
                                    onClick={handleLogin}
                                    disabled={!email || !password || emailStatus === 'error' || passwordStatus === 'error'}
                                >
                                    Se connecter
                                </Button>
                            </form>
                            <Button
                                className="w-100 mt-2 py-3"
                                onClick={() => navigate('/register')}
                            >S'inscrire</Button>
                        </Col>
                    </Row>
                </div>
                {isOpen && (
                    <div className="modal-backdrop" onClick={() => {onClose();setResetPasswordEmail("");setResetPasswordEmailStatus(0)}}>
                        <Modal style={{ padding: "0px", width: "100%" }} hideCloseButton={true} isOpen={isOpen} className={"modal flex flex-col h-max"} onClick={(e) => e.stopPropagation()}>
                            <ModalContent className={"rounded-lg border shadow-sm bg-white max-w-96"}>
                                <ModalHeader className={"font-bold text-2xl mt-3 mb-2 mx-auto"}>
                                    RÉINITIALISER VOTRE MOT DE PASSE
                                </ModalHeader>
                                <form onSubmit={handlePasswordReset}>
                                    <ModalBody>
                                        <div className="w-full">
                                            <span className="block text-gray-500 font-medium mb-1">Votre adresse mail <span className={"text-custom-blue"}>*</span></span>
                                            <Input type="email" onChange={handleResetPasswordEmailChange} status={resetPasswordEmailStatus} prefix={<FontAwesomeIcon icon={faEnvelope}/>} style={{ color: resetPasswordEmailStatus === 'error' ? 'red' : 'gray', paddingRight: "10px", borderColor: resetPasswordEmailStatus === 'error' ? 'red' : resetPasswordEmailStatus === 'success' ? 'green' : undefined }} placeholder="Votre adresse mail" value={resetPasswordEmail} className="w-full rounded-lg"/>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter className={"flex flex-col justify-between mt-3"}>
                                        <div className={"flex justify-between gap-2"}>
                                            <Button icon={<CloseOutlined/>} className={"rounded-lg w-44"} onClick={() => {onClose();setResetPasswordEmail("");setResetPasswordEmailStatus(0)}}>
                                                Annuler
                                            </Button>
                                            <Popconfirm
                                                title="Réinitialiser votre mot de passe ?"
                                                description={"Un email va être envoyé à '" + resetPasswordEmail + "'."}
                                                okText="Oui"
                                                cancelText="Non"
                                                onConfirm={handlePasswordReset}
                                            >
                                                <Button
                                                    icon={<EditOutlined />}
                                                    type="primary"
                                                    className="rounded-lg w-44"
                                                    disabled={!resetPasswordEmail || resetPasswordEmailStatus === 'error'}
                                                >
                                                    Réinitialiser
                                                </Button>
                                            </Popconfirm>
                                        </div>
                                    </ModalFooter>
                                </form>
                            </ModalContent>
                        </Modal>
                    </div>
                )}
            </div>
        </Watermark>
    );
};

export default Login;