import React, {useEffect, useState} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from "@fullcalendar/interaction"
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import momentPlugin from '@fullcalendar/moment';
import listPlugin from "@fullcalendar/list";
import frLocale from "@fullcalendar/core/locales/fr";
import deLocale from "@fullcalendar/core/locales/de";
import enLocale from "@fullcalendar/core/locales/en-au";
import {Flex} from "rebass";
import moment from "moment";
import {useWindowDimensions} from "../../contexts/WindowDimensionsContext";
import PageTitle from "../../components/PageTitle";
import {useTranslation} from "../../contexts/TranslationContext";
import text from "../../translations/pages/Calendar/Calendar";
import 'rsuite-color-picker/lib/styles.css'
import "../../css/calendar.css"
import MyText from "../../components/basics/MyText";
import {useAuth} from "../../contexts/AuthContext";
import {Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure} from "@nextui-org/react";
import {CloseOutlined, DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import axios from "axios";
import {Input, Select, Button, DatePicker, Radio, Popconfirm} from "antd";
import dayjs from 'dayjs';
import fr from 'antd/es/date-picker/locale/fr_FR';


const { TextArea } = Input;
const { RangePicker } = DatePicker;
const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
        result.push(i);
    }
    return result;
};
const disabledDate = (current) => {
    return current && current < dayjs().endOf('day');
};
const frLocalePickup = {
    ...fr,
    lang: {
        ...fr.lang,
        fieldDateFormat: 'DD-MM-YYYY',
        fieldDateTimeFormat: 'DD-MM-YYYY HH:mm',
        yearFormat: 'YYYY',
        cellYearFormat: 'YYYY',
    },
};


const Calendar = ({ backendApiUrl }) => {
    const language = useTranslation().currentLanguage;
    const texts = (text.get(language))
    const authContext = useAuth();
    const windowDimensions = useWindowDimensions();
    const [view, setView] = useState('dayGridMonth');
    const [events, setEvents] = useState([]);
    const [filter, setFilter] = useState("");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [elementId, setElementId] = useState(null);
    const [newElement, setNewElement] = useState({_id: "", title: "", allDay: false, startDate: "", endDate: "", startTime: "", endTime: "", description: "", concern: [], url: ""});
    const [eventsShow, setEventsShow] = useState([]);
    const calendarRef = React.useRef(null);

    useEffect(() => {
        fetchElements()
    }, []);

    useEffect(() => {
        concernSort(events)
    }, [filter]);

    const concernSort = (events) => {
        const userConcernSet = new Set();

        if (authContext.currentUser?.concern) {
            authContext.currentUser.concern.forEach((element) => {
                userConcernSet.add(element);
            });
        }

        const userConcerns = Array.from(userConcernSet);

        if (userConcerns.length !== 0) {
            const concernedEvents = events.filter((event) => {
                const matchesUserConcern = event.extendedProps.concern.some((r) => userConcerns.includes(r));

                const matchesFilter = filter === "" || event.extendedProps.concern.some((r) => r.includes(filter));

                return matchesUserConcern && matchesFilter;
            });

            concernedEvents.sort((a, b) => {
                return new Date(a.startDate) - new Date(b.startDate);
            });

            setEventsShow(concernedEvents);
        } else {
            setEventsShow([]);
        }
    };



    const fetchElements = async () => {
        try {
            const response = await axios.get(`${backendApiUrl}/events/`);
            if (response.status === 200 && response.data.response.length > 0) {
                const tmpEvents = response.data.response.map((event) => {
                    const eventObject= {
                        id: event._id,
                        title: event.title,
                        allDay: event.allDay,
                        textColor: "var(--Black)",
                        backgroundColor: "var(--White)",
                        borderColor: "var(--Black)",
                        extendedProps: {
                            id: event._id,
                            startDate: event.startDate,
                            endDate: event.endDate,
                            startTime: event.startTime,
                            endTime: event.endTime,
                            description: event.description ? event.description : "",
                            concern: event.concern ? event.concern : [],
                            url: event.url ? event.url : null,
                        },
                        overlap: true,
                    };
                    eventObject.start = event.startTime ? event.startDate + "T" + event.startTime : event.startDate
                    eventObject.end = event.startTime ? event.endDate + "T" + event.endTime : event.endDate

                    return eventObject;
                });
                setEvents(tmpEvents)
                concernSort(tmpEvents)
            } else if (response.status === 404) {
                setEvents([]);
            }
        } catch (error) {
            setEvents([]);
            console.error('Error fetching events:', error);
        }
    };

    const handleElementSubmit = async (event) => {
        event?.preventDefault();

        try {
            if (elementId) {
                await axios.put(`${backendApiUrl}/events/id/${elementId}`, newElement, {
                    headers: { "Content-Type": "application/json" },
                });
            } else {
                await axios.post(`${backendApiUrl}/events/`, newElement, {
                    headers: { "Content-Type": "application/json" },
                });
            }

            resetForm();
            fetchElements();
        } catch (error) {
            console.error("Error submitting event:", error);
        }
    };

    const handleElementDelete = async () => {
        try {
            await axios.delete(`${backendApiUrl}/events/id/${elementId}`);
            resetForm();
            fetchElements()
        } catch (error) {
            console.error("Error deleting event:", error);
        }
    }

    const resetForm = () => {
        setElementId(null);
        setNewElement({
            _id: "",
            title: "",
            allDay: false,
            startDate: "",
            endDate: "",
            startTime: "",
            endTime: "",
            description: "",
            concern: [],
            url: "",
        });
    }

    const handleViewChange = (viewChange) => {
        setView(viewChange.view.type)
    }

    const handleEventClick = (event) => {
        if (authContext.currentUser.isAdmin) {
            setNewElement(prevElements => ({ ...prevElements, title: event.event.title }));
            setNewElement(prevElements => ({ ...prevElements, allDay: event.event.allDay }));
            setNewElement(prevElements => ({ ...prevElements, startDate: event.event.extendedProps.startDate }));
            setNewElement(prevElements => ({ ...prevElements, endDate: event.event.extendedProps.endDate }));
            setNewElement(prevElements => ({ ...prevElements, startTime: event.event.extendedProps.startTime}));
            setNewElement(prevElements => ({ ...prevElements, endTime: event.event.extendedProps.endTime}));
            setNewElement(prevElements => ({ ...prevElements, description: event.event.extendedProps.description}));
            setNewElement(prevElements=> ({ ...prevElements, concern: event.event.extendedProps.concern}));
            setNewElement(prevElements => ({ ...prevElements, url: event.event.extendedProps.url}));
            setElementId(event.event.extendedProps.id ? event.event.extendedProps.id : null)
            onOpen()
        }
    };

    const handleDateSelect = (arg) => {
        if (!authContext.currentUser.isAdmin) {
            return;
        }

        if (disabledDate(dayjs(arg.dateStr))) {
            alert("Impossible de créér un événement inférieur ou égal à la date d'aujourd'hui.");
            return;
        }

        setNewElement(prevElement => ({
            ...prevElement,
            startDate: dayjs(arg.dateStr).format("YYYY-MM-DD"),
            endDate: dayjs(arg.dateStr).format("YYYY-MM-DD"),
            startTime: arg.dateStr ? dayjs(arg.dateStr).format("HH:mm") : "00:00",
            endTime: arg.dateStr ? dayjs(arg.dateStr).format("HH:mm") : "23:59"
        }));

        onOpen();
    };

    const handleDateRangeSelect = (selectInfo) => {
        if (!authContext.currentUser.isAdmin) {
            return;
        }
        const { start, end, startStr, endStr } = selectInfo;

        if (disabledDate(dayjs(startStr)) || disabledDate(dayjs(endStr))) {
            alert("Impossible de créer un événement débutant à une date inférieur ou égal à la date d'aujourd'hui.");
            return;
        }

        const formattedStartDate = dayjs(startStr).format("YYYY-MM-DD");
        const formattedEndDate = view === "dayGridMonth" ? dayjs(endStr).subtract(1, 'day').format("YYYY-MM-DD") : dayjs(endStr).format("YYYY-MM-DD");

        const timeStart = start.getHours() + ":" + start.getMinutes();
        const timeEnd = end.getHours() + ":" + end.getMinutes();

        setNewElement(prevElement => ({
            ...prevElement,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            startTime: (timeStart !== "0:0" || timeStart !== "00:00") ? formatTime(timeStart) : "",
            endTime: (timeEnd !== "0:0" || timeEnd !== "00:00") ? formatTime(timeEnd) : ""
        }));

        onOpen();
    };

    const handleRangeChange = (dates) => {
        if (dates && dates.length === 2) {
            const formattedStartDate = dates[0].format('YYYY-MM-DD');
            const formattedEndDate = dates[1].format('YYYY-MM-DD');

            setNewElement(prevElement => ({
                ...prevElement,
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                startTime: dates[0].format('HH:mm'),
                endTime: dates[1].format('HH:mm')
            }));
        }
    };

    function formatTime(value) {
        const [hours, minutes] = value.split(":").map(Number);
        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");
        return `${formattedHours}:${formattedMinutes}`;
    }

    const calculateAspectRatio = () => {
        const width = windowDimensions.windowWidth;

        if (width >= 1600) {
            return 1 + (2.2 * (width - 1000)) / 1600;
        } else if (width <= 1000) {
            return 1;
        } else {
            return 1 + (1.39 * (width - 1000)) / 900;
        }
    };

    return (
        <div style={{marginBottom: "auto"}}>
            {/*<Toaster richColors />*/}
            <PageTitle title={texts.pageTitle}>
                {authContext.currentUser?.isAdmin && windowDimensions.wWCheck(1000) &&
                    <Button onClick={onOpen} type={"primary"} icon={<PlusOutlined/>} style={{ height: "min-content", marginTop: "auto", marginBottom: "auto" }} className="me-2">
                        {texts.new}
                    </Button>
                }
                {authContext.currentUser?.concern && authContext.currentUser?.concern.length > 1 && windowDimensions.wWCheck(1000) &&
                    <Select onChange={(values) => values === undefined ? setFilter("") : setFilter(values)} className={windowDimensions.wWCheck(500) ?  "w-56 mr-5 ml-auto" : "w-100"} placeholder="Filtrer l'entreprise" allowClear={true}>
                        {authContext.currentUser?.concern.sort().map((concern) => (
                            <Select.Option key={concern} value={concern}>{concern}</Select.Option>
                        ))}
                    </Select>
                }
            </PageTitle>

            <Flex flexDirection={"column"} backgroundColor={"var(--GhostWhite)"} justifyContent={"center"} className="calendar-container">
                {windowDimensions.wWCheck(1000) ?
                    <FullCalendar
                        plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin, momentPlugin, momentTimezonePlugin, listPlugin]}
                        dateClick={handleDateSelect}
                        select={handleDateRangeSelect}
                        eventClick={handleEventClick}
                        initialView={view}
                        themeSystem={"standard"}
                        dayHeaderFormat={{weekday: 'long', month: 'numeric', day: 'numeric'}}
                        navLinks={true}
                        weekNumbers={true}
                        eventStartEditable={false}
                        selectable={true}
                        selectMirror={true}
                        editable={false}
                        weekText={"S"}
                        aspectRatio={calculateAspectRatio()}
                        style={{height: "100%", width: "100%"}}
                        timeZone={"Europe/Paris"}
                        eventContent={renderEventContent}
                        locale={language}
                        locales={[frLocale, deLocale, enLocale]}
                        viewDidMount={handleViewChange}
                        viewWillUnmount={handleViewChange}
                        ref={calendarRef}
                        businessHours={{
                            daysOfWeek: [1, 2, 3, 4, 5],
                            startTime: '08:00',
                            endTime: '18:00',
                        }}
                        slotLabelFormat={{
                            hour: '2-digit',
                            minute: '2-digit',
                            omitZeroMinute: false,
                            meridiem: false
                        }}
                        headerToolbar={{
                            left: 'dayGridMonth,dayGridWeek,dayGridDay,listWeek',
                            center: 'title',
                            right: 'today,prevYear,prev,next,nextYear'
                        }}
                        events={eventsShow}
                    />
                :
                    <MyText value={texts.screenSizeTooSmall} style={{fontSize: "var(--BigFontSize)", marginTop: "150px", textAlign: "center", padding: "15px"}}/>
                }
                </Flex>

                {isOpen && (
                    <div className="modal-backdrop" onClick={() => {onClose();resetForm();}}>
                        <Modal style={{ padding: "0px", width: "100%" }} hideCloseButton={true} isOpen={isOpen} className={"modal flex flex-col h-max overflow-y-auto"} onClick={(e) => e.stopPropagation()}>
                            <ModalContent className={"rounded-lg border shadow-sm bg-white max-w-96 overflow-y-auto"}>
                                <ModalHeader className={"font-bold text-2xl mt-3 mb-2 mx-auto"}>
                                    {elementId ? "MODIFIER UN ÉVÉNEMENT" : "CRÉER UN ÉVÉNEMENT"}
                                </ModalHeader>
                                <form onSubmit={handleElementSubmit}>
                                    <ModalBody>
                                        <div className="w-full">
                                            <Flex justifyContent={"space-between"}>
                                                <span className="block text-gray-500 font-medium mb-1 my-auto">Période <span className={"text-custom-blue"}>*</span></span>
                                                <Radio.Group value={newElement.allDay ? "allDay" : "time"} onChange={(e) => setNewElement({ ...newElement, allDay: e.target.value === "allDay" })}>
                                                    <Radio.Button value="time">Durée spécifique</Radio.Button>
                                                    <Radio.Button value="allDay">Toute la journée</Radio.Button>
                                                </Radio.Group>
                                            </Flex>
                                            <RangePicker showTime={!newElement.allDay ? { format: 'HH:mm' } : false} allowClear={true} locale={frLocalePickup} disabledDate={disabledDate} className="w-full mt-2" value={newElement.startDate && newElement.endDate ? [dayjs(`${newElement.startDate} ${newElement.startTime}`, "YYYY-MM-DD HH:mm"), dayjs(`${newElement.endDate} ${newElement.endTime}`, "YYYY-MM-DD HH:mm")] : null} onChange={handleRangeChange}/>
                                        </div>
                                        <div className="w-full">
                                            <span className="block text-gray-500 font-medium mb-1">Entreprises concernée(s) <span className={"text-custom-blue"}>*</span></span>
                                            <Select value={newElement.concern} onChange={(values) => setNewElement({ ...newElement, concern: values })} mode="multiple" placeholder="Sélectionnez les entreprises concernées" className="w-full rounded-lg">
                                                {authContext.currentUser?.concern.map((concern) => (
                                                    <Select.Option key={concern} value={concern}>
                                                        {concern}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </div>
                                        <div className="w-full">
                                            <span className="block text-gray-500 font-medium mb-1">Titre de l'événement <span className={"text-custom-blue"}>*</span></span>
                                            <Input style={{fontSize: windowDimensions.wWCheck(500) ? "" : "16px"}} type="text" placeholder="Titre de l'événement" value={newElement.title} onChange={(e) => setNewElement({ ...newElement, title: e.target.value })} className="w-full rounded-lg"/>
                                        </div>
                                        <div className="w-full">
                                            <span className="block text-gray-500 font-medium mb-1">Contenu de l'événement</span>
                                            <TextArea autoSize type="text" placeholder="Contenu de l'événement" value={newElement.description} onChange={(e) => setNewElement({ ...newElement, description: e.target.value })} className="w-full rounded-lg"/>
                                        </div>
                                        <div className="w-full">
                                            <span className="block text-gray-500 font-medium mb-1">Lien</span>
                                            <Input value={newElement.url || ""} onChange={(e) => setNewElement({ ...newElement, url: e.target.value })} placeholder={`Url`} style={{fontSize: windowDimensions.wWCheck(500) ? "" : "16px", width: "100%", marginLeft: "3px"}}/>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter className={"flex flex-col justify-between mt-3"}>
                                        <div className={"flex justify-between gap-2"}>
                                            <Button icon={<CloseOutlined/>} className={"rounded-lg w-44"} onClick={() => {onClose();resetForm();}}>
                                                Annuler
                                            </Button>
                                            {elementId && (
                                                <Popconfirm
                                                    title="Supprimer l'événement"
                                                    description="Êtes vous sur de vouloir le supprimer ?"
                                                    okText="Oui"
                                                    cancelText="Non"
                                                    onConfirm={() => {onClose(); resetForm();handleElementDelete()}}
                                                >
                                                    <Button icon={<DeleteOutlined/>} type="primary" danger={true} className={"rounded-lg w-44 mx-auto"}>
                                                        Supprimer
                                                    </Button>
                                                </Popconfirm>
                                            )}
                                            {elementId ? (
                                                    <Popconfirm
                                                        title="Modifier l'événement"
                                                        description="Êtes-vous sûr de vouloir le modifier ?"
                                                        okText="Oui"
                                                        cancelText="Non"
                                                        onConfirm={handleElementSubmit}
                                                    >
                                                        <Button
                                                            icon={<EditOutlined />}
                                                            type="primary"
                                                            className="rounded-lg w-44"
                                                        >
                                                            Modifier
                                                        </Button>
                                                    </Popconfirm>
                                                ) :
                                                <Popconfirm
                                                    title="Créer l'événement"
                                                    description="Êtes-vous sûr de vouloir le créer ?"
                                                    okText="Oui"
                                                    cancelText="Non"
                                                    onConfirm={handleElementSubmit}
                                                >
                                                    <Button
                                                        icon={<PlusOutlined />}
                                                        type="primary"
                                                        className="rounded-lg w-44"
                                                    >
                                                        Créer
                                                    </Button>
                                                </Popconfirm>
                                            }
                                        </div>
                                    </ModalFooter>
                                </form>
                            </ModalContent>
                        </Modal>
                    </div>
                )}
            </div>
        )
}

function renderEventContent(eventInfo) {
    const title = eventInfo.event.title;
    const allDay = eventInfo.event.allDay;
    const startDate = moment(eventInfo.event.extendedProps.startDate).format("DD/MM/yyyy");
    const endDate = moment(eventInfo.event.extendedProps.endDate).format("DD/MM/yyyy");
    const startTime = eventInfo.event.extendedProps.startTime;
    const endTime = eventInfo.event.extendedProps.endTime;
    const description = eventInfo.event.extendedProps.description;
    const url = eventInfo.event.extendedProps.url;

    const contentHeight = calculateContentHeight(title, startDate, endDate, startTime, endTime, description, url);

    const maxEventHeight = 50;

    const shouldOverflow = contentHeight > maxEventHeight;

    return (
        <Flex flexDirection={"column"} padding={"5px"} className={"bg-white rounded w-100"} style={{ overflow: shouldOverflow ? "scroll" : "hidden" }}>
            <b style={{ wordBreak: "break-word" }}>{title}</b>
            <div className={"mt-2 flex flex-col px-2"}>
                    <i style={{ wordBreak: "break-word" }}>
                        {startDate} - {endDate}
                    </i>
                    <i style={{ wordBreak: "break-word" }}>
                        {startTime} - {endTime}
                    </i>
                    <i style={{ marginTop: "10px", wordBreak: "break-word" }}>{description}</i>
                    <a style={{ marginTop: "5px", wordBreak: "break-word" }} href={url}>
                        {url}
                    </a>
            </div>
        </Flex>
    );
}

function calculateContentHeight(title, startDate, endDate, startTime, endTime, description, url) {
    const lineHeight = 16;
    const padding = 10;
    const margin = 5;

    const titleHeight = lineHeight + 2 * padding;
    const dateHeight = lineHeight + 2 * padding;
    const descriptionHeight = description ? lineHeight + 2 * padding : 0;
    const urlHeight = url ? lineHeight + 2 * padding : 0;

    const totalContentHeight = titleHeight + dateHeight + descriptionHeight + urlHeight;

    const totalHeightWithMargins = totalContentHeight + 3 * margin;

    return totalHeightWithMargins;
}

export default Calendar