import "../../css/constants.css";
import React, {useEffect, useState} from "react";
import PageTitle from "../../components/PageTitle";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useAuth} from "../../contexts/AuthContext";
import {useWindowDimensions} from "../../contexts/WindowDimensionsContext";
import {CloseOutlined, DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {Button, Input, message, Popconfirm, Select, Switch, Table, Tag, Typography} from "antd";
import {Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure} from "@nextui-org/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faPen, faTrash} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {Dropzone} from "../../components/dropzone";
import {Flex} from "rebass";
import Card from "react-bootstrap/Card";
import Avatar from "../../components/Avatar";
const { Search } = Input;
const { TextArea } = Input;

export default function Contacts({ backendApiUrl }) {
    const authContext = useAuth();
    const navigate = useNavigate()
    const windowDimensions = useWindowDimensions();
    const [contacts, setContacts] = useState([]);
    const [displayedContacts, setDisplayedContacts] = useState([]);
    const [filter, setFilter] = useState("")
    const [isPictureViewerOpen, setIsPictureViewerOpen] = useState(false);
    const [pictureViewerPicture, setPictureViewerPicture] = useState("");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [file, setFile] = useState(null);
    const [fileRemoved, setFileRemoved] = useState(false);
    const [elementId, setElementId] = useState(null);
    const [newElement, setNewElement] = useState({_id: "", firstName: "", lastName: "", role: "", mail: "", phone: "", phoneMobile: "", concern: [], content: "", picture: "", url: []});
    const [numberOfLinks, setNumberOfLinks] = useState( 0);
    const [highlighted, setHighlighted] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const idToSearch = searchParams.get("id");
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const columns = [
        {
            title: "Image",
            dataIndex: 'picture',
            key: 'picture',
            render: (_, data) => (
                data.picture ? <img className="max-w-[75px] rounded-full aspect-square object-cover border" src={data.picture} alt="Photo de profil" /> : <Avatar size="3xl" className='text-16' variant="name">{data.firstName[0]+data.lastName[0]}</Avatar>
            ),
            width: '130px',
        },
        {
            title: "Prénom",
            dataIndex: 'firstName',
            key: 'firstName',
            width: '175px',
        },
        {
            title: "Nom",
            dataIndex: 'lastName',
            key: 'lastName',
            width: '175px',
        },
        {
            title: "Role",
            dataIndex: 'role',
            key: 'role',
            width: '230px',
        },
        {
            title: "Email",
            dataIndex: 'mail',
            key: 'mail',
            width: '300px',
        },
        {
            title: "Téléphone",
            dataIndex: 'phone',
            key: 'phone',
            render: (_, record) => (
                <div>
                    {record.phone && <p>Fixe : {record.phone}</p>}
                    {record.phoneMobile && <p>Mobile : {record.phoneMobile}</p>}
                </div>
            ),
            width: '250px',
        },
        {
            title: "Entreprises concernées",
            dataIndex: 'concern',
            key: 'concern',
            render: (concern) => (
                <div className="flex flex-wrap gap-2 mt-1 mb-1 max-w-[740px]">
                    {concern.map((item, index) => (
                        <Tag key={index} className="lowercase">{item}</Tag>
                    ))}
                </div>
            ),
            width: '800px',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <div className="flex gap-2">
                    {authContext.currentUser.isSuperAdmin && <Popconfirm
                        title="Supprimer le contact"
                        description="Êtes-vous sûr de vouloir le supprimer ?"
                        okText="Oui"
                        cancelText="Non"
                        onConfirm={() => handleElementDelete(record._id || "")}
                    >
                        <Button icon={<FontAwesomeIcon icon={faTrash} />} danger />
                    </Popconfirm>}
                    {authContext.currentUser.isSuperAdmin && <Button icon={<FontAwesomeIcon icon={faPen} />} onClick={() => { onOpen(); setNewElement(record); setNumberOfLinks(record.url ? record.url.length : 0); setElementId(record._id); setFile(record.picture)}} />}
                    <Button icon={<FontAwesomeIcon icon={faEye} />} onClick={() => { onOpen(); setNewElement(record); setElementId("view") }} />
                </div>
            ),
            width: 200,
        },
    ];

    useEffect(() => {
        fetchElements();
    }, []);

    useEffect(() => {
        handleFilter()
    }, [filter]);

    const fetchElements = async () => {
        try {
            const response = await axios.get(`${backendApiUrl}/contacts/`);
            if (response.status === 200 && response.data.response.length > 0) {
                const changed = response.data.response
                    .map((item) => {
                        const pictureUrl = item.picture
                            ? `${backendApiUrl.replace("/api", "")}/${item.picture.replace('/root/apps/kimmel-intra/dest/server/', '')}`
                            : "";
                        return { ...item, picture: pictureUrl };
                    })
                setContacts(changed.sort((a, b) => {
                    return a.firstName.localeCompare(b.firstName);
                }));
                setDisplayedContacts(changed.sort((a, b) => {
                    return a.firstName.localeCompare(b.firstName);
                }));
            } else if (response.status === 404) {
                setContacts([]);
                setDisplayedContacts([])
            }
        } catch (error) {
            setContacts([]);
            setDisplayedContacts([]);
            console.error('Error fetching contacts:', error);
        }
    };

    const handleElementSubmit = async (event) => {
        event?.preventDefault();
        const formData = new FormData();

        Object.keys(newElement).forEach((key) => {
            if (fileRemoved && key === "picture") {
                formData.append(key, "");
            } else if (Array.isArray(newElement[key])) {
                if (key === "url") {
                    formData.append(key, JSON.stringify(newElement[key]));
                } else {
                    newElement[key].forEach((item) => formData.append(key, item));
                }
            } else {
                formData.append(key, newElement[key]);
            }
        });

        if (file && !fileRemoved) {
            formData.append("picture", file);
        }

        try {
            if (elementId) {
                await axios.put(`${backendApiUrl}/contacts/id/${elementId}`, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                });
            } else {
                await axios.post(`${backendApiUrl}/contacts/`, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                });
            }
            resetForm();
            fetchElements();
            onClose()
        } catch (error) {
            console.error("Error submitting contact:", error);
        }
    };

    const handleElementDelete = async (id) => {
        try {
            await axios.delete(`${backendApiUrl}/contacts/id/${id ? id : elementId}`);
            resetForm();
            fetchElements()
        } catch (error) {
            console.error("Error deleting contact:", error);
        }
    }

    const handleMultipleElementDelete = async (selectedKeys) => {
        try {
            selectedKeys.selectedRowKeys.map((id) =>
                handleElementDelete(id)
            )
            setSelectedRowKeys([]);
            fetchElements();
        } catch (error) {
            console.error("Error deleting contacts:", error);
            message.error("An error occurred while deleting contacts.");
        }
    };

    const resetForm = () => {
        setElementId(null);
        setNewElement({
            _id: "",
            firstName: "",
            lastName: "",
            role: "",
            mail: "",
            phone: "",
            phoneMobile: "",
            concern: [],
            content: "",
            picture: "",
            url: []
        });
        setNumberOfLinks(0)
        setFile(null)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys) => setSelectedRowKeys(newSelectedRowKeys),
    };

    const handleSearch = (value) => {
        const searchValue = value.toLowerCase();
        if (searchValue) {
            const filteredContacts = contacts.filter(contact => {
                const { content, ...otherFields } = contact;

                return Object.values(otherFields).some(val =>
                    String(val).toLowerCase().includes(searchValue)
                );
            });

            setDisplayedContacts(
                filteredContacts.sort((a, b) => a.firstName.localeCompare(b.firstName))
            );
        } else {
            setDisplayedContacts(contacts);
        }
    };

    const handleFilter = (value) => {
        if (filter !== "") {
            const filteredcontacts = contacts.filter(contact =>
               contact.concern.includes(filter)
            );
            setDisplayedContacts(filteredcontacts.sort((a, b) => {
                return a.firstName.localeCompare(b.firstName);
            }));
        } else {
            setDisplayedContacts(contacts);
        }
    };

    const handleTitleChange = (index, value) => {
        setNewElement(prevElement => {
            const updatedUrls = [...prevElement.url];

            if (index < updatedUrls.length) {
                updatedUrls[index].title = value;
            } else {
                updatedUrls.push({ title: value, link: "" });
            }

            return {
                ...prevElement,
                url: updatedUrls,
            };
        });
    };

    const handleLinkChange = (index, value) => {
        setNewElement(prevElement => {
            const updatedUrls = [...prevElement.url];

            if (index < updatedUrls.length) {
                updatedUrls[index].link = value;
            } else {
                updatedUrls.push({ title: "", link: value });
            }

            return {
                ...prevElement,
                url: updatedUrls,
            };
        });
    };

    const handleRemoveLink = (indexToRemove) => {
        setNumberOfLinks(prevNumberOfLinks => prevNumberOfLinks - 1);

        setNewElement(prevElement => {
            const updatedUrls = prevElement.url.filter((_, index) => index !== indexToRemove);

            return {
                ...prevElement,
                url: updatedUrls,
            };
        });
    };

    return (
        <div style={{marginBottom: "auto"}}>
            <PageTitle title={"Contacts"}>
                {authContext.currentUser?.isSuperAdmin &&
                    <Button onClick={onOpen} type={"primary"} icon={<PlusOutlined/>} style={{ height: "min-content", marginTop: "auto", marginBottom: "auto" }} className="me-2">
                        Ajouter un contact
                    </Button>
                }
                <Search placeholder="Recherche" onSearch={handleSearch} className={windowDimensions.wWCheck(500) ? "w-56" : "w-100"} allowClear enterButton/>
                {selectedRowKeys.length > 0 && (
                    <Popconfirm
                        title="Supprimer les contacts"
                        description="Êtes vous sur de vouloir les supprimer ?"
                        okText="Oui"
                        cancelText="Non"
                        onConfirm={() => {handleMultipleElementDelete(rowSelection); setSelectedRowKeys([])}}
                    >
                        <Button
                            icon={<FontAwesomeIcon icon={faTrash} />}
                            danger
                            style={{ marginLeft: 8 }}
                        >
                            Supprimer les éléments sélectionnés
                        </Button>
                    </Popconfirm>
                )}
                {authContext.currentUser?.concern && authContext.currentUser?.concern.length > 1 &&
                    <Select onChange={(values) => values === undefined ? setFilter("") : setFilter(values)} className={windowDimensions.wWCheck(500) ?  "w-56 mr-5 ml-auto" : "w-100"} placeholder="Filtrer l'entreprise" allowClear={true}>
                        {authContext.currentUser?.concern.sort().map((concern) => (
                            <Select.Option key={concern} value={concern}>{concern}</Select.Option>
                        ))}
                    </Select>
                }
            </PageTitle>

            <Table
                rowKey="_id"
                rowSelection={authContext.currentUser.isSuperAdmin ? rowSelection : null}
                columns={columns}
                expandable={{
                    expandedRowRender: (record) =>
                        <div className="w-full">
                            <p style={{ margin: 0, whiteSpace: "pre" }}>{record.content}</p>
                            <Flex flexDirection="column" style={{ gap: "8px", maxHeight: "150px", overflowY: "auto", marginTop: "10px" }}>
                                <p style={{ marginBottom: "3px", fontSize: "14px", fontWeight: "bold"}} className={"text-lg"}>Liens associés:</p>
                                {record.url.map((link, index) => (
                                    <Flex
                                        key={index}
                                        alignItems="center"
                                        style={{
                                            flexWrap: "wrap",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Card.Link
                                            onClick={() =>
                                                link.link.startsWith("https://") ? window.open(link.link, "_blank", "noopener,noreferrer") : navigate(link.link)
                                            }
                                            style={{
                                                cursor: "pointer",
                                                textDecoration: "underline",
                                                textUnderlineOffset: "3px",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                maxWidth: "95%",
                                            }}
                                            title={link.link}
                                        >
                                            {link.title}
                                        </Card.Link>
                                    </Flex>
                                ))}
                            </Flex>
                        </div>
                }}
                dataSource={displayedContacts}
                pagination={{ pageSize: 30 }}
                size="middle"
                scroll={{ x: windowDimensions.windowWidth < 900 ? 800 : undefined }}
                style={{marginTop: "30px"}}
            />

            {isOpen && (
                <div className="modal-backdrop" onClick={() => {onClose(); resetForm();}}>
                    <Modal style={{ padding: "0px", width: "100%" }} hideCloseButton={true} isOpen={isOpen} className={"modal flex flex-col h-max overflow-y-auto"} onClick={(e) => e.stopPropagation()}>
                        <ModalContent className={"rounded-lg border shadow-sm bg-white max-w-96 overflow-y-auto"}>
                            <ModalHeader className={"font-bold text-2xl mt-3 mb-2 mx-auto"}>
                                {elementId ? elementId === "view" ? "CONTACT" : "MODIFIER UN CONTACT" : "CRÉER UN CONTACT"}
                            </ModalHeader>
                            <form onSubmit={handleElementSubmit}>
                                <ModalBody>
                                    {elementId === "view" && newElement.picture && <img className="mt-1 mx-auto max-w-[150px] rounded-full aspect-square object-cover border" src={newElement.picture} alt="Photo de profil" />}
                                    <div className="w-full">
                                        <span className="block text-gray-500 font-medium mb-1">Prénom <span className={"text-custom-blue"}>{elementId !== "view" ? "*" : ""}</span></span>
                                        {elementId !== "view" && <Input type="text" placeholder="Prénom" value={newElement.firstName} onChange={(e) => setNewElement({ ...newElement, firstName: e.target.value })} className="w-full rounded-lg" style={{fontSize: windowDimensions.wWCheck(500) ? "" : "16px"}}/>}
                                        {elementId === "view" && <Typography.Text>{newElement.firstName}</Typography.Text>}
                                    </div>
                                    <div className="w-full">
                                        <span className="block text-gray-500 font-medium mb-1">Nom <span className={"text-custom-blue"}>{elementId !== "view" ? "*" : ""}</span></span>
                                        {elementId !== "view" && <Input type="text" placeholder="Nom" value={newElement.lastName} onChange={(e) => setNewElement({ ...newElement, lastName: e.target.value })} className="w-full rounded-lg" style={{fontSize: windowDimensions.wWCheck(500) ? "" : "16px"}}/>}
                                        {elementId === "view" && <Typography.Text>{newElement.lastName}</Typography.Text>}
                                    </div>
                                    <div className="w-full">
                                        <span className="block text-gray-500 font-medium mb-1">Role <span className={"text-custom-blue"}>{elementId !== "view" ? "*" : ""}</span></span>
                                        {elementId !== "view" && <Input type="text" placeholder="Role" value={newElement.role} onChange={(e) => setNewElement({ ...newElement, role: e.target.value })} className="w-full rounded-lg" style={{fontSize: windowDimensions.wWCheck(500) ? "" : "16px"}}/>}
                                        {elementId === "view" && <Typography.Text>{newElement.role}</Typography.Text>}
                                    </div>
                                    <div className="w-full">
                                        <span className="block text-gray-500 font-medium mb-1">Email</span>
                                        {elementId !== "view" && <Input type="text" placeholder="Email" value={newElement.mail} onChange={(e) => setNewElement({ ...newElement, mail: e.target.value })} className="w-full rounded-lg" style={{fontSize: windowDimensions.wWCheck(500) ? "" : "16px"}}/>}
                                        {elementId === "view" && <Typography.Text>{newElement.mail}</Typography.Text>}
                                    </div>
                                    <div className="w-full">
                                        <span className="block text-gray-500 font-medium mb-1">Téléphone</span>
                                        {elementId !== "view" && <Input type="text" placeholder="Téléphone Fixe" value={newElement.phone} onChange={(e) => setNewElement({ ...newElement, phone: e.target.value })} className="w-full rounded-lg" style={{fontSize: windowDimensions.wWCheck(500) ? "" : "16px"}}/>}
                                        {elementId !== "view" && <Input type="text" placeholder="Téléphone Mobile" value={newElement.phoneMobile} onChange={(e) => setNewElement({ ...newElement, phoneMobile: e.target.value })} className="w-full rounded-lg mt-2" style={{fontSize: windowDimensions.wWCheck(500) ? "" : "16px"}}/>}
                                        {elementId === "view" && <div className={"flex flex-column"}>
                                            <Typography.Text>Fixe: {newElement.phone}</Typography.Text>
                                            <Typography.Text className={"mt-1"}>{"Mobile: " + newElement.phoneMobile}</Typography.Text>
                                        </div>}
                                    </div>
                                    <div className="w-full">
                                        <span className="block text-gray-500 font-medium mb-1">Entreprises concernées <span className={"text-custom-blue"}>{elementId !== "view" ? "*" : ""}</span></span>
                                        {elementId !== "view" && <Select value={newElement.concern} onChange={(values) => setNewElement({ ...newElement, concern: values })} mode="multiple" placeholder="Sélectionnez les entreprises concernées" className="w-full rounded-lg">
                                            {authContext.currentUser?.concern.map((concern) => (
                                                <Select.Option key={concern} value={concern}>
                                                    {concern}
                                                </Select.Option>
                                            ))}
                                        </Select>}
                                        {elementId === "view" &&
                                            <div className={"flex gap-y-2 flex-wrap"}>
                                                {newElement.concern.map((concern, index) => (
                                                    <Tag key={index} className="lowercase">{concern}</Tag>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                    <div className="w-full">
                                        <span className="block text-gray-500 font-medium mb-1">Description</span>
                                        {elementId !== "view" && <TextArea autoSize type="text" placeholder="Description" value={newElement.content} onChange={(e) => setNewElement({ ...newElement, content: e.target.value })} className="w-full rounded-lg"/>}
                                        {elementId === "view" && <Typography.Text>{newElement.content}</Typography.Text>}
                                    </div>
                                    {elementId !== "view" && <div className="w-full">
                                        <span className="block text-gray-500 font-medium mb-1">Image</span>
                                        <Dropzone onChange={(file) => setFile(file)} initialFile={file} setFileRemoved={setFileRemoved} fileType={"image"}/>
                                    </div>}
                                    {elementId !== "view" &&
                                        Array.from(Array(numberOfLinks).keys()).map((link, index) => (
                                        <div className="w-full">
                                            <span className="block text-gray-500 font-medium mb-1">Lien {index+1}</span>
                                            <Flex justifyContent={"space-between"} key={index}>
                                                <Input value={newElement.url[index]?.title || ""} onChange={(e) => handleTitleChange(index, e.target.value)} placeholder={`Titre ${index + 1}`} style={{fontSize: windowDimensions.wWCheck(500) ? "" : "16px", width: "100%", marginRight: "3px"}}/>
                                                <Input value={newElement.url[index]?.link || ""} onChange={(e) => handleLinkChange(index, e.target.value)} placeholder={`$Url ${index + 1}`} style={{fontSize: windowDimensions.wWCheck(500) ? "" : "16px", width: "100%", marginLeft: "3px"}}/>
                                                <Button icon={<CloseOutlined />} style={{height: "min-content", marginTop: "auto", marginBottom: "auto", marginLeft: "6px", paddingLeft: "4px", paddingRight: "4px", color: "red"}} onClick={() => handleRemoveLink(index)}/>
                                            </Flex>
                                        </div>
                                    ))}
                                    {elementId === "view" &&
                                        <Flex flexDirection="column" style={{ gap: "8px", maxHeight: "150px", overflowY: "auto" }}>
                                            {newElement.url.length !== 0 && <p className={"block text-gray-500 font-medium mb-1"}>Liens associés:</p>}
                                            {newElement.url.map((link, index) => (
                                                <Flex
                                                    key={index}
                                                    alignItems="center"
                                                    style={{
                                                        flexWrap: "wrap",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <Card.Link
                                                        onClick={() =>
                                                            link.link.startsWith("https://") ? window.open(link.link, "_blank", "noopener,noreferrer") : navigate(link.link)
                                                        }
                                                        style={{
                                                            cursor: "pointer",
                                                            textDecoration: "underline",
                                                            textUnderlineOffset: "3px",
                                                            whiteSpace: "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            maxWidth: "95%",
                                                        }}
                                                        title={link.link}
                                                    >
                                                        {link.title}
                                                    </Card.Link>
                                                </Flex>
                                            ))}
                                        </Flex>
                                    }
                                    {elementId !== "view" && <Button icon={<PlusOutlined />} type={"text"} disabled={numberOfLinks >= 5} style={{ width: "100%" }} className="me-2" onClick={() => {if (numberOfLinks < 5) setNumberOfLinks(numberOfLinks + 1);}}>
                                        Ajouter un lien
                                    </Button>}
                                </ModalBody>
                                <ModalFooter className={"flex flex-col justify-between mt-3"}>
                                    <div className={`flex ${elementId === "view" ? "justify-center" : "justify-between"} gap-2`}>
                                        <Button icon={<CloseOutlined/>} className={"rounded-lg w-44"} onClick={() => {onClose();resetForm();}}>
                                            {elementId === "view" ? "Fermer" : "Annuler"}
                                        </Button>
                                        {elementId && elementId !== "view" && (
                                            <Popconfirm
                                                title="Supprimer le contact"
                                                description="Êtes vous sur de vouloir le supprimer ?"
                                                okText="Oui"
                                                cancelText="Non"
                                                onConfirm={() => {onClose(); resetForm(); handleElementDelete()}}
                                            >
                                                <Button icon={<DeleteOutlined/>} type="primary" danger={true} className={"rounded-lg w-44 mx-auto"}>
                                                    Supprimer
                                                </Button>
                                            </Popconfirm>
                                        )}
                                        {elementId && elementId !== "view" ? (
                                            <Popconfirm
                                                title="Modifier le contact"
                                                description="Êtes-vous sûr de vouloir le modifier ?"
                                                okText="Oui"
                                                cancelText="Non"
                                                onConfirm={() => { onClose(); resetForm(); handleElementSubmit();}}
                                            >
                                                <Button
                                                    icon={<EditOutlined />}
                                                    type="primary"
                                                    className="rounded-lg w-44"
                                                >
                                                    Modifier
                                                </Button>
                                            </Popconfirm>
                                        ) : elementId !== "view" ? (
                                            <Popconfirm
                                                title="Créer le contact"
                                                description="Êtes-vous sûr de vouloir le créer ?"
                                                okText="Oui"
                                                cancelText="Non"
                                                onConfirm={handleElementSubmit}
                                            >
                                                <Button
                                                    icon={<PlusOutlined />}
                                                    type="primary"
                                                    className="rounded-lg w-44"
                                                >
                                                    Créer
                                                </Button>
                                            </Popconfirm>
                                        ) : null}
                                    </div>
                                </ModalFooter>
                            </form>
                        </ModalContent>
                    </Modal>
                </div>
            )}
        </div>
    );
};