import React, {useEffect, useState} from 'react';
import {Col, Row, Button, Typography, Input, Space, message, Popconfirm, Switch, Tag} from 'antd';
import { Card, Dropdown, Nav } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import { getUser } from '../../utils/localStorage';
import {CloseOutlined, EditOutlined} from '@ant-design/icons';
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure} from "@nextui-org/react";
import "../../css/constants.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faKey, faUser} from "@fortawesome/free-solid-svg-icons";
import Avatar from "../../components/Avatar";
import axios from "axios";
import {useWindowDimensions} from "../../contexts/WindowDimensionsContext";

const Profile = ({ backendApiUrl }) => {
    const auth = useAuth();
    const [user, setUser] = useState(getUser());
    const [modifyPassword, setModifyPassword] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        passwordVerification: '',
        concern: [],
        userValidated: false,
        isAdmin: false,
        isSuperAdmin: false,
        isEmailValid: false
    });
    const [status, setStatus] = useState({
        emailStatus: 0,
        passwordStatus: 0,
        verificationPasswordStatus: 0,
    });
    const { isOpen, onOpen, onClose } = useDisclosure();
    const windowDimensions = useWindowDimensions();

    useEffect(() => {
        setFormData(user)
    }, [user]);

    useEffect(() => {
        if (modifyPassword) {
            setFormData(prev => ({...prev, password: ""}));
            setFormData(prev => ({...prev, passwordVerification: ""}));
        } else
            setFormData(prev => ({ ...prev, password: user.password }));
    }, [modifyPassword]);

    const handleInputChange = (field, value) => {
        setFormData(prev => ({ ...prev, [field]: value }));

        if (field === "email") {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            setStatus(prev => ({ ...prev, emailStatus: emailPattern.test(value) ? 'success' : 'error' }));
        }
        if (field === "password") {
            setStatus(prev => ({ ...prev, passwordStatus: value.length > 5 ? 'success' : 'error' }));
        }
        if (field === "passwordVerification") {
            setStatus(prev => ({ ...prev, verificationPasswordStatus: value.length > 5 ? 'success' : 'error' }));
        }
    };

    const handleUpdateUser = async () => {
        if (modifyPassword && formData.password !== formData.passwordVerification) {
            message.error("Les mots de passes ne correspondent pas.")
            return
        }
        try {
            const res = await axios.put(`${backendApiUrl}/user/id/${user._id}`, formData);
            if (res) {
                message.success("Votre profil à été modifé.");
                auth.logout()
            } else {
                message.error("Une erreur est survenue, impossible de modifier votre profil");
            }
        } catch (error) {
            console.error(error);
            message.error("Une erreur est survenue, impossible de modifier votre profil");
        }
    };

    return (
        <div style={{ marginBottom: "auto" }}>
            <div>
                <h2 className="mb-15">Votre profil</h2>

                <Row justify="center">
                    <Col xs={24} sm={20} md={16} lg={12}>
                        <Card
                            bordered={false}
                            style={{
                                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                                borderRadius: '10px',
                                maxWidth: '100%',
                                overflow: 'hidden'
                            }}
                        >
                            <Space direction="vertical" size="middle" style={{ display: 'flex', alignItems: 'center' }}>
                                <Card style={{width: "max-content"}} className="position-relative border-0">
                                    <Card.Body className="p-0 mt-4">
                                        <div className="d-flex flex-row align-items-center gap-2 pt-3 px-3">
                                            <Avatar size="4xl" variant="name">{auth.currentUser?.firstName[0] + auth.currentUser?.lastName[0]}</Avatar>
                                            <div className={"ml-6"} style={{ maxWidth: '550px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: "ellipsis" }}>
                                                <h6 className="text-black text-3xl mb-2">{auth.currentUser?.firstName + ' ' + auth.currentUser?.lastName}</h6>
                                                <span className="text-muted text-xl">{auth.currentUser?.email}</span>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Typography className={"mt-5"}>J'ai accès aux informations des entreprises suivantes:</Typography>
                                <div className="flex flex-wrap gap-2 mt-1 mb-1 max-w-[740px] px-5">
                                    {user.concern.map((item, index) => (
                                        <Tag key={index} className="lowercase">{item}</Tag>
                                    ))}
                                </div>
                                <Button type="primary" className={"mt-4"} icon={<EditOutlined />} onClick={onOpen}>
                                    Modifier vos informations
                                </Button>,
                            </Space>
                        </Card>
                    </Col>
                </Row>
            </div>

            {isOpen && (
                <div className="modal-backdrop" onClick={() => {onClose();}}>
                    <Modal style={{ padding: "0px", width: "100%" }} hideCloseButton={true} isOpen={isOpen} className={"modal flex flex-col h-max overflow-y-auto"} onClick={(e) => e.stopPropagation()}>
                        <ModalContent className={"rounded-lg border shadow-sm bg-white max-w-96 overflow-y-auto"}>
                            <ModalHeader className={"font-bold text-2xl mt-3 mb-2 mx-auto"}>
                                MODIFIER VOTRE PROFIL
                            </ModalHeader>
                            <form onSubmit={handleUpdateUser}>
                                <ModalBody>
                                    <div style={{ marginBottom: '10px' }}>
                                        <label htmlFor="firstName" style={{ fontSize: '14px', fontWeight: '500' }}>Prénom</label>
                                        <Input
                                            id="firstName"
                                            placeholder="Prénom"
                                            value={formData.firstName}
                                            onChange={(e) => handleInputChange('firstName', e.target.value)}
                                            prefix={<FontAwesomeIcon icon={faUser} style={{ fontSize: windowDimensions.wWCheck(500) ? "" : "16px", color: 'gray', paddingRight: "10px" }} />}
                                        />
                                    </div>

                                    <div style={{ marginBottom: '10px' }}>
                                        <label htmlFor="lastName" style={{ fontSize: '14px', fontWeight: '500' }}>Nom</label>
                                        <Input
                                            id="lastName"
                                            placeholder="Nom"
                                            value={formData.lastName}
                                            onChange={(e) => handleInputChange('lastName', e.target.value)}
                                            prefix={<FontAwesomeIcon icon={faUser} style={{ fontSize: windowDimensions.wWCheck(500) ? "" : "16px", color: 'gray', paddingRight: "10px" }} />}
                                        />
                                    </div>

                                    <div style={{ marginBottom: '10px' }}>
                                        <label htmlFor="email" style={{ fontSize: '14px', fontWeight: '500' }}>Adresse e-mail</label>
                                        <Input
                                            id="email"
                                            type="email"
                                            placeholder="Adresse e-mail"
                                            value={formData.email}
                                            onChange={(e) => handleInputChange('email', e.target.value)}
                                            status={status.emailStatus}
                                            prefix={<FontAwesomeIcon icon={faEnvelope} style={{ fontSize: windowDimensions.wWCheck(500) ? "" : "16px", color: status.emailStatus === 'error' ? 'red' : 'gray', paddingRight: "10px" }} />}
                                        />
                                    </div>

                                    <div style={{ marginBottom: '10px', width: "100%"}}>
                                        <span style={{ fontSize: '14px', fontWeight: '500', display: "block" }}>Modifier votre mot de passe ?</span>
                                        <Switch onClick={() => setModifyPassword(!modifyPassword)} checked={modifyPassword} checkedChildren="Oui" unCheckedChildren="Non" className={"min-w-14 mt-1"}/>
                                    </div>

                                    {modifyPassword && <div style={{ marginBottom: '10px' }}>
                                        <label htmlFor="password" style={{ fontSize: '14px', fontWeight: '500' }}>Nouveau mot de passe</label>
                                        <Input.Password
                                            id="password"
                                            placeholder="Nouveau mot de passe"
                                            value={formData.password}
                                            onChange={(e) => handleInputChange('password', e.target.value)}
                                            status={status.passwordStatus}
                                            prefix={<FontAwesomeIcon icon={faKey} style={{ fontSize: windowDimensions.wWCheck(500) ? "" : "16px", color: status.passwordStatus === 'error' ? 'red' : 'gray', paddingRight: "10px" }} />}
                                        />
                                    </div>}

                                    {modifyPassword && <div style={{ marginBottom: '10px' }}>
                                        <label htmlFor="passwordVerification" style={{ fontSize: '14px', fontWeight: '500' }}>Vérification du nouveau mot de passe</label>
                                        <Input.Password
                                            id="passwordVerification"
                                            placeholder="Vérification du nouveau mot de passe"
                                            value={formData.passwordVerification}
                                            onChange={(e) => handleInputChange('passwordVerification', e.target.value)}
                                            status={status.verificationPasswordStatus}
                                            prefix={<FontAwesomeIcon icon={faKey} style={{ fontSize: windowDimensions.wWCheck(500) ? "" : "16px", color: status.verificationPasswordStatus === 'error' ? 'red' : 'gray', paddingRight: "10px" }} />}
                                        />
                                    </div>}
                                </ModalBody>
                                <ModalFooter className={"flex flex-col justify-between mt-3"}>
                                    <div className={"flex justify-between gap-2"}>
                                        <Button icon={<CloseOutlined/>} className={"rounded-lg w-44"} onClick={() => {onClose();}}>
                                            Annuler
                                        </Button>
                                        <Popconfirm
                                            title="Modifier votre profil ?"
                                            okText="Oui"
                                            cancelText="Non"
                                            onConfirm={handleUpdateUser}
                                        >
                                            <Button
                                                icon={<EditOutlined />}
                                                type="primary"
                                                className="rounded-lg w-44"
                                                disabled={!formData.firstName || !formData.lastName || !formData.email || status.emailStatus === 'error' || (modifyPassword ? (!formData.password || !formData.passwordVerification || status.passwordStatus === 'error' || status.verificationPasswordStatus === 'error') : false)}
                                            >
                                                Modifier
                                            </Button>
                                        </Popconfirm>
                                    </div>
                                </ModalFooter>
                            </form>
                        </ModalContent>
                    </Modal>
                </div>
            )}
        </div>
    );
};

export default Profile;
